// Types
import {
	FETCH_REQUESTS_DATA_REQUEST,
	FETCH_REQUESTS_DATA_SUCCESS,
	FETCH_REQUESTS_DATA_FAILURE,
	FETCH_COMPLETED_LOANS_REQUEST,
	FETCH_COMPLETED_LOANS_SUCCESS,
	FETCH_COMPLETED_LOANS_FAILURE,
	FETCH_CURRENT_LOANS_REQUEST,
	FETCH_CURRENT_LOANS_SUCCESS,
	FETCH_CURRENT_LOANS_FAILURE,
	FETCH_PROCESSING_LOANS_REQUEST,
	FETCH_PROCESSING_LOANS_SUCCESS,
	FETCH_PROCESSING_LOANS_FAILURE,
	FETCH_COLLECTIONS_LOANS_REQUEST,
	FETCH_COLLECTIONS_LOANS_SUCCESS,
	FETCH_COLLECTIONS_LOANS_FAILURE,
	FETCH_DEFAULT_LOANS_REQUEST,
	FETCH_DEFAULT_LOANS_SUCCESS,
	FETCH_DEFAULT_LOANS_FAILURE,
	FETCH_MORE_COMPLETED_LOANS_REQUEST,
	FETCH_MORE_COMPLETED_LOANS_SUCCESS,
	FETCH_MORE_COMPLETED_LOANS_FAILURE,
	FETCH_MORE_CURRENT_LOANS_REQUEST,
	FETCH_MORE_CURRENT_LOANS_SUCCESS,
	FETCH_MORE_CURRENT_LOANS_FAILURE,
	FETCH_MORE_PROCESSING_LOANS_REQUEST,
	FETCH_MORE_PROCESSING_LOANS_SUCCESS,
	FETCH_MORE_PROCESSING_LOANS_FAILURE,
	FETCH_MORE_COLLECTIONS_LOANS_REQUEST,
	FETCH_MORE_COLLECTIONS_LOANS_SUCCESS,
	FETCH_MORE_COLLECTIONS_LOANS_FAILURE,
	FETCH_MORE_DEFAULT_LOANS_REQUEST,
	FETCH_MORE_DEFAULT_LOANS_SUCCESS,
	FETCH_MORE_DEFAULT_LOANS_FAILURE,
	FETCH_CHECK_BALANCE_BY_ID_REQUEST,
	FETCH_CHECK_BALANCE_BY_ID_SUCCESS,
	FETCH_CHECK_BALANCE_BY_ID_FAILURE,
	FETCH_ENTERPRISE_FUND_BY_ID_REQUEST,
	FETCH_ENTERPRISE_FUND_BY_ID_SUCCESS,
	FETCH_ENTERPRISE_FUND_BY_ID_FAILURE,
	DISMISS_LOAN_REQUEST,
	DISMISS_LOAN_SUCCESS,
	DISMISS_LOAN_FAILURE,
	CANCEL_LOAN_REQUEST,
	CANCEL_LOAN_SUCCESS,
	CANCEL_LOAN_FAILURE,
} from "./types";

// Initial State
const initialState = {
	requestsData: {
		loading: true,
		requests: [],
		error: "",
	},
	completedLoansData: {
		loading: true,
		loans: [],
		error: "",
		loadingMore: false,
	},
	currentLoansData: {
		loading: true,
		loans: [],
		error: "",
		loadingMore: false,
	},
	processingLoansData: {
		loading: true,
		loans: [],
		error: "",
		loadingMore: false,
	},
	collectionsLoansData: {
		loading: true,
		loans: [],
		error: "",
		loadingMore: false,
	},
	defaultLoansData: {
		loading: true,
		loans: [],
		error: "",
		loadingMore: false,
	},
	checkBalance: {
		loading: false,
		acceptedOffer: {},
		error: "",
	},
	enterpriseFund: {
		loading: false,
		loan: {},
		error: "",
	},
	dismissLoan: {
		loading: false,
		error: "",
	},
	cancelLoan: {
		loading: false,
		error: "",
	},
};

// Reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {
		// Get Requests Data
		case FETCH_REQUESTS_DATA_REQUEST:
			return {
				...state,
				requestsData: { ...state.requestsData, loading: true },
			};
		case FETCH_REQUESTS_DATA_SUCCESS:
			return {
				...state,
				requestsData: { ...state.requestsData, loading: false, requests: action.payload },
			};
		case FETCH_REQUESTS_DATA_FAILURE:
			return {
				...state,
				requestsData: { ...state.requestsData, loading: false, error: action.payload },
			};

		// Get Completed Loans
		case FETCH_COMPLETED_LOANS_REQUEST:
			return {
				...state,
				completedLoansData: { ...state.completedLoansData, loading: true },
			};
		case FETCH_COMPLETED_LOANS_SUCCESS:
			return {
				...state,
				completedLoansData: { ...state.completedLoansData, loading: false, loans: action.payload },
			};
		case FETCH_COMPLETED_LOANS_FAILURE:
			return {
				...state,
				completedLoansData: { ...state.completedLoansData, loading: false, error: action.payload },
			};

		// Get More Completed Loans
		case FETCH_MORE_COMPLETED_LOANS_REQUEST:
			return {
				...state,
				completedLoansData: { ...state.completedLoansData, loadingMore: true },
			};
		case FETCH_MORE_COMPLETED_LOANS_SUCCESS:
			return {
				...state,
				completedLoansData: {
					...state.completedLoansData,
					loadingMore: false,
					loans: {
						...action.payload,
						results: [...state.completedLoansData.loans.results, ...action.payload.results],
					},
				},
			};
		case FETCH_MORE_COMPLETED_LOANS_FAILURE:
			return {
				...state,
				completedLoansData: { ...state.completedLoansData, loadingMore: false, error: action.payload },
			};

		// Get Current Loans
		case FETCH_CURRENT_LOANS_REQUEST:
			return {
				...state,
				currentLoansData: { ...state.currentLoansData, loading: true },
			};
		case FETCH_CURRENT_LOANS_SUCCESS:
			return {
				...state,
				currentLoansData: { ...state.currentLoansData, loading: false, loans: action.payload },
			};
		case FETCH_CURRENT_LOANS_FAILURE:
			return {
				...state,
				currentLoansData: { ...state.currentLoansData, loading: false, error: action.payload },
			};

		// Get More Current Loans
		case FETCH_MORE_CURRENT_LOANS_REQUEST:
			return {
				...state,
				currentLoansData: { ...state.currentLoansData, loadingMore: true },
			};
		case FETCH_MORE_CURRENT_LOANS_SUCCESS:
			return {
				...state,
				currentLoansData: {
					...state.currentLoansData,
					loadingMore: false,
					loans: {
						...action.payload,
						results: [...state.currentLoansData.loans.results, ...action.payload.results],
					},
				},
			};
		case FETCH_MORE_CURRENT_LOANS_FAILURE:
			return {
				...state,
				currentLoansData: { ...state.currentLoansData, loadingMore: false, error: action.payload },
			};

		// Get Processing Loans
		case FETCH_PROCESSING_LOANS_REQUEST:
			return {
				...state,
				processingLoansData: { ...state.processingLoansData, loading: true },
			};
		case FETCH_PROCESSING_LOANS_SUCCESS:
			return {
				...state,
				processingLoansData: { ...state.processingLoansData, loading: false, loans: action.payload },
			};
		case FETCH_PROCESSING_LOANS_FAILURE:
			return {
				...state,
				processingLoansData: { ...state.processingLoansData, loading: false, error: action.payload },
			};

		// Get More Processing Loans
		case FETCH_MORE_PROCESSING_LOANS_REQUEST:
			return {
				...state,
				processingLoansData: { ...state.processingLoansData, loadingMore: true },
			};
		case FETCH_MORE_PROCESSING_LOANS_SUCCESS:
			return {
				...state,
				processingLoansData: {
					...state.processingLoansData,
					loadingMore: false,
					loans: {
						...action.payload,
						results: [...state.processingLoansData.loans.results, ...action.payload.results],
					},
				},
			};
		case FETCH_MORE_PROCESSING_LOANS_FAILURE:
			return {
				...state,
				processingLoansData: { ...state.processingLoansData, loadingMore: false, error: action.payload },
			};

		// Get Collections Loans
		case FETCH_COLLECTIONS_LOANS_REQUEST:
			return {
				...state,
				collectionsLoansData: { ...state.collectionsLoansData, loading: true },
			};
		case FETCH_COLLECTIONS_LOANS_SUCCESS:
			return {
				...state,
				collectionsLoansData: { ...state.collectionsLoansData, loading: false, loans: action.payload },
			};
		case FETCH_COLLECTIONS_LOANS_FAILURE:
			return {
				...state,
				collectionsLoansData: { ...state.collectionsLoansData, loading: false, error: action.payload },
			};

		// Get More Collections Loans
		case FETCH_MORE_COLLECTIONS_LOANS_REQUEST:
			return {
				...state,
				collectionsLoansData: { ...state.collectionsLoansData, loadingMore: true },
			};
		case FETCH_MORE_COLLECTIONS_LOANS_SUCCESS:
			return {
				...state,
				collectionsLoansData: {
					...state.collectionsLoansData,
					loadingMore: false,
					loans: {
						...action.payload,
						results: [...state.collectionsLoansData.loans.results, ...action.payload.results],
					},
				},
			};
		case FETCH_MORE_COLLECTIONS_LOANS_FAILURE:
			return {
				...state,
				collectionsLoansData: { ...state.collectionsLoansData, loadingMore: false, error: action.payload },
			};

		// Get Default Loans
		case FETCH_DEFAULT_LOANS_REQUEST:
			return {
				...state,
				defaultLoansData: { ...state.defaultLoansData, loading: true },
			};
		case FETCH_DEFAULT_LOANS_SUCCESS:
			return {
				...state,
				defaultLoansData: { ...state.defaultLoansData, loading: false, loans: action.payload },
			};
		case FETCH_DEFAULT_LOANS_FAILURE:
			return {
				...state,
				defaultLoansData: { ...state.defaultLoansData, loading: false, error: action.payload },
			};

		// Get More Default Loans
		case FETCH_MORE_DEFAULT_LOANS_REQUEST:
			return {
				...state,
				defaultLoansData: { ...state.defaultLoansData, loadingMore: true },
			};
		case FETCH_MORE_DEFAULT_LOANS_SUCCESS:
			return {
				...state,
				defaultLoansData: {
					...state.defaultLoansData,
					loadingMore: false,
					loans: {
						...action.payload,
						results: [...state.defaultLoansData.loans.results, ...action.payload.results],
					},
				},
			};
		case FETCH_MORE_DEFAULT_LOANS_FAILURE:
			return {
				...state,
				defaultLoansData: { ...state.defaultLoansData, loadingMore: false, error: action.payload },
			};

		// Check Balance By ID
		case FETCH_CHECK_BALANCE_BY_ID_REQUEST:
			return {
				...state,
				checkBalance: { ...state.checkBalance, loading: true },
			};
		case FETCH_CHECK_BALANCE_BY_ID_SUCCESS:
			return {
				...state,
				checkBalance: { ...state.checkBalance, loading: false, acceptedOffer: action.payload, error: "" },
			};
		case FETCH_CHECK_BALANCE_BY_ID_FAILURE:
			return {
				...state,
				checkBalance: { ...state.checkBalance, loading: false, acceptedOffer: {}, error: action.payload },
			};

		// Enterprise Fund By ID
		case FETCH_ENTERPRISE_FUND_BY_ID_REQUEST:
			return {
				...state,
				enterpriseFund: { ...state.enterpriseFund, loading: true },
			};
		case FETCH_ENTERPRISE_FUND_BY_ID_SUCCESS:
			return {
				...state,
				enterpriseFund: { ...state.enterpriseFund, loading: false, loan: action.payload },
			};
		case FETCH_ENTERPRISE_FUND_BY_ID_FAILURE:
			return {
				...state,
				enterpriseFund: { ...state.enterpriseFund, loading: false, error: action.payload },
			};

		// Dismiss Loan
		case DISMISS_LOAN_REQUEST:
			return {
				...state,
				dismissLoan: { ...state.dismissLoan, loading: true },
			};
		case DISMISS_LOAN_SUCCESS:
			return {
				...state,
				processingLoansData: {
					...state.processingLoansData,
					loans: {
						...state.processingLoansData.loans,
						results: state.processingLoansData.loans.results.filter((item) => item.id !== action.loanId),
						count: state.processingLoansData.loans.count - 1,
					},
				},
				dismissLoan: { ...state.dismissLoan, loading: false },
			};
		case DISMISS_LOAN_FAILURE:
			return {
				...state,
				dismissLoan: { ...state.dismissLoan, loading: false, error: action.payload },
			};

		// Cancel Loan
		case CANCEL_LOAN_REQUEST:
			return {
				...state,
				cancelLoan: { ...state.cancelLoan, loading: true },
			};
		case CANCEL_LOAN_SUCCESS:
			return {
				...state,
				processingLoansData: {
					...state.processingLoansData,
					loans: {
						...state.processingLoansData.loans,
						results: state.processingLoansData.loans.results.filter((item) => item.id !== action.loanId),
						count: state.processingLoansData.loans.count - 1,
					},
				},
				cancelLoan: { ...state.cancelLoan, loading: false },
			};
		case CANCEL_LOAN_FAILURE:
			return {
				...state,
				cancelLoan: { ...state.cancelLoan, loading: false, error: action.payload },
			};

		default:
			return state;
	}
};

export default reducer;
