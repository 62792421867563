import http from "./httpService";
import store from "../redux/store";


const apiVersion = store.getState().general.apiVersion;
const apiEndpoint = "/v" + apiVersion + "/loan_requests/";

// Get Loan Requests
export async function getLoanRequests() {
	return await http.get(apiEndpoint);
}

// Get More Loan Requests
export async function getMoreLoanRequests(next) {
	return await http.get(next);
}

// Filter Requests
export async function fliterRequests(query) {
	return await http.get(apiEndpoint + query);
}

// Get Favourite Loan Requests
export async function getFavLoanRequest() {
	return await http.get("/v" + apiVersion + "/fav_loan_request/");
}

// Get More Favourite Loan Requests
export async function getMoreFavLoanRequest(next) {
	return await http.get(next);
}

// Get Borrower's Completed Loans history
export async function getBorrowerCompletedLoans(borrower_id) {
	return await http.get("/v" + apiVersion + "/borrower/" + borrower_id + "/completed_loans/");
}

// Favourite a Loan Request
export async function favLoanRequest(request_id) {
	return await http.post("/v" + apiVersion + "/fav_loan_request/", {
		loan_request: request_id,
	});
}

// Unfavourite a Loan Request
export async function unfavLoanRequest(fav_loan_request_id) {
	return await http.delete("/v" + apiVersion + "/fav_loan_request/" + fav_loan_request_id + "/");
}

// Unfavourite all Loan Requests
export async function clearFavLoanRequests() {
	return await http.delete("/v" + apiVersion + "/fav_loan_request/clear/");
}

// Offer a Loan for a Loan Request
export async function dismissLoanRequest(request_id) {
	return await http.post(apiEndpoint + request_id + "/dismiss/");
}

// Dismiss a Loan Request
export async function offerLoanRequest(request_id, offered_interest) {
	return await http.post(apiEndpoint + request_id + "/enterprise_make_offer/", {
		offered_interest: offered_interest,
	});
}

// Get Completed Loans
export async function getCompletedLoans() {
	return await http.get("/v" + apiVersion + "/loans/completed_loan/");
}

// Get More Completed Loans
export async function getMoreCompletedLoans(apiEndpointForMoreLoans) {
	return await http.get(apiEndpointForMoreLoans);
}

// Get Current Loans
export async function getCurrentLoans() {
	return await http.get("/v" + apiVersion + "/loans/current_loan/");
}

// Get More Current Loans
export async function getMoreCurrentLoans(apiEndpointForMoreLoans) {
	return await http.get(apiEndpointForMoreLoans);
}

// Get Processing Loans
export async function getProcessingLoans() {
	return await http.get("/v" + apiVersion + "/user/offers/");
}

// Get More Processing Loans
export async function getMoreProcessingLoans(apiEndpointForMoreLoans) {
	return await http.get(apiEndpointForMoreLoans);
}

// Get Collections Loans
export async function getCollectionsLoans() {
	return await http.get("/v" + apiVersion + "/loans/collection_loan/");
}

// Get More Collections Loans
export async function getMoreCollectionsLoans(apiEndpointForMoreLoans) {
	return await http.get(apiEndpointForMoreLoans);
}

// Get Default Loans
export async function getDefaultLoans() {
	return await http.get("/v" + apiVersion + "/loans/default_loan/");
}

// Get More Default Loans
export async function getMoreDefaultLoans(apiEndpointForMoreLoans) {
	return await http.get(apiEndpointForMoreLoans);
}

// Get Loan Payments
export async function getLoanPaymentsById(id) {
	return await http.get("/v" + apiVersion + "/loans/" + id + "/payments/");
}

// Check Balance By ID
export async function checkBalanceById(id) {
	return await http.get("/v" + apiVersion + "/accepted_offers/" + id + "/check_balance/");
}

// Enterprise Fund By ID
export async function enterpriseFundById(id, data) {
	return await http.post("/v" + apiVersion + "/accepted_offers/" + id + "/enterprise_fund/", {
		data,
	});
}

// Dismiss Loan
export async function dismissLoan(id, data) {
	return await http.post("/v" + apiVersion + "/pending_offers/" + id + "/dismiss/", {
		data,
	});
}

// Cancel Loan
export async function cancelLoan(id, data) {
	return await http.post("/v" + apiVersion + "/accepted_offers/" + id + "/cancel/", {
		data,
	});
}

// Get Lenme Prediction
export async function getLenmePrediction(loan_request) {
	return await http.post("/v" + apiVersion + "/data_marketplace/lenme_prediction/get_prediction/", {
		loan_request,
	});
}

// Send Lenme Prediction Receipt
export async function sendLenmePredictionReceipt(receipt, device, productId, loan_request) {
	return await http.post("/v" + apiVersion + "/data_marketplace/lenme_prediction/send_receipt/", {
		receipt: receipt,
		device: device,
		product_id: productId,
		loan_request: loan_request,
	});
}

export default {
	getLoanRequests,
	getMoreLoanRequests,
	fliterRequests,
	getFavLoanRequest,
	getMoreFavLoanRequest,
	getBorrowerCompletedLoans,
	favLoanRequest,
	unfavLoanRequest,
	clearFavLoanRequests,
	dismissLoanRequest,
	offerLoanRequest,
	getCompletedLoans,
	getMoreCompletedLoans,
	getCurrentLoans,
	getMoreCurrentLoans,
	getProcessingLoans,
	getMoreProcessingLoans,
	getCollectionsLoans,
	getMoreCollectionsLoans,
	getDefaultLoans,
	getMoreDefaultLoans,
	getLoanPaymentsById,
	checkBalanceById,
	enterpriseFundById,
	dismissLoan,
	cancelLoan,
	getLenmePrediction,
	sendLenmePredictionReceipt,
};
