import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { showToast } from "./redux";

// Google Analytics
import ReactGA from "react-ga";

// Services
import { onMessageListener } from "./services/firebase-messaging";
import { onPerformanceListener } from "./services/firebase-performance";

// Components
import ProtectedRoute from "./components/common/protectedRoute/protectedRoute";
import HomePage from "./components/homePage/homePage";
import LoginPage from "./components/loginPage/loginPage";
import modalMessageSuccess from "./components/loginPage/modalMessageSuccess";
import modalMessageFail from "./components/loginPage/modalMessageFail";
import ForgotPasswordPage from "./components/forgotPasswordPage/forgotPasswordPage";
import SignUpPage from "./components/signUpPage/signUpPage";
import SignUpProcess from "./components/signUpProcess/signUpProcess";
import NotFoundPage from "./components/notFoundPage/notFoundPage";
import Logout from "./components/logout/logout";
import UserHome from "./components/userHome/userHome";
import LNToast from "./components/common/LNToast/LNToast";



function App() {
	const toast = useSelector((state) => state.general.toast);
	const dispatch = useDispatch();

	// Initialize Google Analytics
	const initializeGoogleAnalytics = () => {
		ReactGA.initialize("G-73GJ6K5EXH");
		ReactGA.pageview(window.location.pathname + window.location.search);
	};

	// When Mount
	useEffect(() => {
		// Initialize google analytics
		initializeGoogleAnalytics();

		// eslint-disable-next-line no-console
	}, []);

	// Firebase Cloud Messaging
	onMessageListener()
		.then((payload) => {
			const { title, body } = payload.notification;
			dispatch(showToast("", title, body));
		})
		.catch((error) => {
			console.log(JSON.stringify(error));
		});

	// Firebase Performance Monitoring
	onPerformanceListener();

	// Render
	return (
		<main>
			<Switch>
				{/* Pre-login */}
				<Route path="/signup" component={SignUpPage} />
				<Route path="/forgot-password" component={ForgotPasswordPage} />
				<Route path="/oauth" component={LoginPage} />
				<Route path="/modalSuccess" component={modalMessageSuccess} />
				<Route path="/modalFail" component={modalMessageFail} />
				<Route path="/" component={HomePage} exact />

				{/* Post-login */}
				<ProtectedRoute path="/signup-process" component={SignUpProcess} />
				<ProtectedRoute path="/user" component={UserHome} />
				<ProtectedRoute path="/logout" component={Logout} />

				{/* Not Found */}
				<Route path="/not-found" component={NotFoundPage} />
				<Redirect to="/not-found" />
			</Switch>

			{/* Toasts */}
			{toast.length > 0 && (
				<div className="toast-container">
					{toast.map((item, index) => (
						<LNToast
							key={index}
							index={index}
							type={item.type}
							title={item.title}
							body={item.body}
							button={item.button}
							onButtonClick={item.onButtonClick}
						/>
					))}
				</div>
			)}
		</main>
	);
}

export default App;
