import React, { Component } from "react";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";

// Import Services
import http from "../../services/httpService";

// Import Components
import LNFloatingInput from "../common/LNFloatingInput/LNFloatingInput";
import ResponseIllustration from "../common/responseIllustration/responseIllustration";

// Import Images
import Logo from "../../img/assets/logo.svg";

// Import CSS
import "./forgotPasswordPage.css";

//import store
import store from "../../redux/store";

class ForgotPassword extends Component {
	state = {
		loading: true,
		data: {
			email: "",
		},
		error: false,
		errorMessage: "",
		success: false,
	};

	// Handle Loading
	handleLoading() {
		const loading = false;
		this.setState({ loading });
	}

	// When Mount
	componentDidMount() {
		// End loading
		this.handleLoading();
	}

	// Helper Methods

	// Render
	render() {
		const { loading, data, success } = this.state;

		const apiVersion = store.getState().general.apiVersion;

		// Validations
		const ValidationSchema = Yup.object().shape({
			email: Yup.string()
				.email("Please enter a valid email address.")
				.max(255, "Too Long!")
				.required("Required field"),
		});

		// Check if loading
		if (loading) return <div>Loading...</div>;

		// Render
		return (
			<div className="bg-landing">
				<div className="container">
					<div className="row no-gutters min-vh-100 justify-content-center align-items-center">
						<div className="col-12 col-md-10 col-lg-7 col-xl-6">
							<div className="pre-login-form bg-white shadow my-3">
								<div className="mb-3 logo">
									<img src={Logo} alt="Logo" />
								</div>

								{success && (
									<>
										<div className="mb-2 title">Thank you</div>
										<div className="mb-3">We’ve sent over instructions to reset your password.</div>

										<div className="mb-2">
											<div className="mb-4 text-center">
												<ResponseIllustration type="success" />
											</div>
											<Link to="/" className="btn btn-primary btn-lg btn-block">
												Done
											</Link>
										</div>
									</>
								)}

								{!success && (
									<>
										<div className="mb-2 title">Password Reset</div>
										<div className="mb-2">
											Please enter email address you used to create your account.
										</div>
										<div className="mb-3">Forgot email? Please try again or contact support.</div>
										<div className="mb-2 font-weight-bold text-primary">Email</div>

										<div className="mb-2">
											<Formik
												initialValues={data}
												validationSchema={ValidationSchema}
												onSubmit={(values, { setSubmitting, setErrors }) => {
													setSubmitting(true);

													http.post(
														`v${apiVersion}/user/forget_password/`,
														{
															email: values.email,
														}
													)
														.then((res) => {
															this.setState({
																error: false,
																success: true,
															});
															setSubmitting(false);
														})
														.catch((error) => {
															console.log("Error: ", error);
															this.setState({
																error: true,
																errorMessages: error.response.data,
															});
															setErrors(this.state.errorMessages);
															setSubmitting(false);
														});
												}}>
												{({
													values,
													errors,
													touched,
													handleChange,
													handleBlur,
													handleSubmit,
													isSubmitting,
												}) => (
													<form onSubmit={handleSubmit}>
														<LNFloatingInput
															size="lg"
															type="email"
															optional={false}
															name="email"
															value={values.email}
															onChange={handleChange}
															onBlur={handleBlur}
															placeholder="Company Email"
															error={
																touched.email && errors.email ? `* ${errors.email}` : ""
															}
														/>

														<small className="text-muted d-block mb-4">
															We will send you an email with your username and instrctions
															on how to reset your password.
														</small>

														<button
															className="btn btn-primary btn-lg btn-block"
															type="submit"
															disabled={isSubmitting}
															onClick={handleSubmit}>
															Reset password
														</button>
													</form>
												)}
											</Formik>
										</div>
									</>
								)}

								<div>
									<div className="mb-2">
										<Link to="/signup" className="text-decoration-none font-size-14 text-dark">
											Sign up
										</Link>
									</div>
									<div className="d-flex font-size-14">
										<div className="mr-1">Return to</div>
										<Link to="/" className="text-decoration-none">
											Sign in
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

// Prop Types
ForgotPassword.propTypes = {};

export default ForgotPassword;
