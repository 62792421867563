import http from "./httpService";
import jwtDecode from "jwt-decode";
import { fetchMoneyMadeSuc,fetchMoneyMadeFai, fetchMoneyMadeSuccess, fetchMoneyMadeFailure } from "../redux";
import store from "../redux/store";


const apiVersion = store.getState().general.apiVersion;
const accessTokenKey = "accessToken";
const refreshTokenKey = "refreshToken";
const modalStatus = "modalStatus";

// Login
export async function login(data) {
	return await http.post("token/", {
		email: data.email,
		password: data.password,
	}).then(
		(res)=>{return res},
		(err)=>{console.log('login error',err)}
		).catch(
			(err)=>{console.log('login error',err)}
		)
	// Store token in localStorage
	// window.open("/modalSuccess");

}


export async function moneyMade(access_token,signature,payload) {
	return await http.post(`v${apiVersion}/money_made/connect_user/`, {
		access_token: access_token,
		signature:signature,
		payload:payload,
	}).then((res) => {
		return res
		// localStorage.setItem(modalStatus, true);
		// return async function (dispatch) {
		// 	try{
		// 		console.log("Res True");
		// dispatch(fetchMoneyMadeSuccess());} 
		// catch (err) {
		// 	console.log("Res error",err);
		// }
	})
	.catch((error) => {
		// localStorage.setItem(modalStatus, true);
		// console.log(localStorage.getItem(modalStatus))
		// return async function (dispatch) {
		// 	console.log("fail to call error")
		// 	dispatch(fetchMoneyMadeFailure());}
		console.log("error money made",error)
	});;
}
	 
		
			
// 	if (response) {
// 		/* Handling response */
// 		switch (response.status) {
// 			case 200:
// 				/* Handling Success */
// 				
// 				console.log("MoneyMade Success")
// 				break;
// 			case 201:
// 				/* Handling Success */
// 				localStorage.setItem(modalStatus, true);
// 				console.log("MoneyMade Success")
// 				break;
// 			case 403:
// 				/* Handling Success */
// 				let	responseJson = await response.json()
// 				localStorage.setItem(modalStatus, false);
// 				console.log(responseJson)
// 				break;
// 			case 404:
// 				/* Handling Success */
// 				localStorage.setItem(modalStatus, false);
// 				console.log("MoneyMade False")
// 				break;
// 			default:
// 				localStorage.setItem(modalStatus, false);
// 				console.log("MoneyMade Fail")
// 				break;
// }}
// }

// Login With JWT
export function loginWithJwt(accessJWT, refreshJWT) {
	localStorage.setItem(accessTokenKey, accessJWT);
	localStorage.setItem(refreshTokenKey, refreshJWT);
}

// Logout
export function logout() {
	localStorage.removeItem(accessTokenKey);
	localStorage.removeItem(refreshTokenKey);
}


// Get Current Account
export function getCurrentAccount() {
	try {
		const jwt = localStorage.getItem(accessTokenKey);
		return jwtDecode(jwt);
	} catch (ex) {
		return null;
	}
}

// Check Auth
export function checkAuth(current) {
	const account = getCurrentAccount();
	if (account && account.user_id === current.user_id) return true;
	return false;
}

export default {
	login,
	loginWithJwt,
	logout,
	getCurrentAccount,
	checkAuth,
	moneyMade,
	fetchMoneyMadeSuccess,
	fetchMoneyMadeFailure,
};
