import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";

// Import Services
import http from "../../../services/httpService";

// Import Utils
import { getRegionList } from "../../../utils/handleRegions";
import { handleReactGAEvents } from "../../../utils/handleReactGAEvents";

// Import Components
import LNFloatingInput from "../../common/LNFloatingInput/LNFloatingInput";
import LNInputMask from "../../common/LNInputMask/LNInputMask";
import LNSelect from "../../common/LNSelect/LNSelect";
import LNError from "../../common/LNError/LNError";

// Import CSS
import "./companyDetails.css";

import store from "../../../redux/store";
class CompanyDetails extends Component {
	state = {
		data: {
			street_address_1: "",
			street_address_2: "",
			city: "",
			state: "",
			zip_code: "",
			phone: "",
			website: "",
		},
		error: false,
		errorMessages: "",
	};

	// Render
	render() {
		const { data, error, errorMessages } = this.state;

		const apiVersion = store.getState().general.apiVersion;
		// Validations
		const ValidationSchema = Yup.object().shape({
			street_address_1: Yup.string().max(255, "Too Long!").required("Required field"),
			street_address_2: Yup.string().max(255, "Too Long!"),
			city: Yup.string().max(255, "Too Long!").required("Required field"),
			state: Yup.string().required("Required field"),
			zip_code: Yup.string()
				.trim()
				.matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter a valid U.S. zipcode.")
				.required("Required field"),
			phone: Yup.string()
				.trim()
				.matches(/^\+1[^1][0-9]{9}$/, "Please enter a valid U.S. phone number.")
				.required("Required field"),
			website: Yup.string().url("Please enter a valid URL."),
		});

		// Render
		return (
			<div className="signup-process-form company-details">
				{/* header */}
				<div className="form-header mb-5">
					<div className="main-form-label">Start investing with Lenme Enterprise</div>
					<div>Tell us more about your business.</div>
				</div>

				{/* Form */}
				<Formik
					initialValues={data}
					validationSchema={ValidationSchema}
					onSubmit={(values, { setSubmitting, setErrors }) => {
						setSubmitting(true);
						const postData = {
							street_address_1: values.street_address_1,
							street_address_2: values.street_address_2,
							city: values.city,
							state: values.state,
							zip_code: values.zip_code,
							phone: values.phone,
							website: values.website,
						};

						Object.keys(postData).forEach((key) => {
							if (!postData[key]) delete postData[key];
						});

						http.post(
							`enterprise/v${apiVersion}/register/company_details/`,
							postData
						)
							.then((res) => {
								this.setState({ error: false });
								setSubmitting(false);
								this.props.incrementfunction();

								// Send GA event
								handleReactGAEvents("Sign_up_step_3");
							})
							.catch((error) => {
								console.log("Error: ", error);
								this.setState({
									error: true,
									errorMessages: error.response.data,
								});
								setErrors(this.state.errorMessages);
								setSubmitting(false);
							});
					}}>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue,
					}) => (
						<form onSubmit={handleSubmit} spellCheck={false}>
							<div className="inputs-wrapper">
								{error && <LNError text={errorMessages} />}

								<LNFloatingInput
									size="lg"
									type="text"
									name="street_address_1"
									value={values.street_address_1}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Company Address 1"
									error={
										touched.street_address_1 && errors.street_address_1
											? `* ${errors.street_address_1}`
											: ""
									}
								/>

								<LNFloatingInput
									size="lg"
									type="text"
									optional={true}
									name="street_address_2"
									value={values.street_address_2}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Company Address 2"
									error={
										touched.street_address_2 && errors.street_address_2
											? `* ${errors.street_address_2}`
											: ""
									}
								/>

								<LNFloatingInput
									size="lg"
									type="text"
									name="city"
									value={values.city}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="City"
									error={touched.city && errors.city ? `* ${errors.city}` : ""}
								/>

								<LNSelect
									name="state"
									size="lg"
									value={values.state}
									onChange={handleChange}
									onBlur={handleBlur}
									defaultOption={{ name: "State", value: "" }}
									options={getRegionList("US")}
									error={touched.state && errors.state ? `* ${errors.state}` : ""}
								/>

								<LNInputMask
									mask="99999"
									maskChar="X"
									value={values.zip_code}
									onChange={(e) => {
										const value = e.target.value.replace(/-/g, "").replace(/X/g, "");
										setFieldValue("zip_code", value);
									}}
									onBlur={handleBlur}
									size="lg"
									type="text"
									name="zip_code"
									placeholder="Zip Code"
									error={touched.zip_code && errors.zip_code ? `* ${errors.zip_code}` : ""}
								/>

								<LNInputMask
									mask="999-999-9999"
									maskChar="X"
									onChange={(e) => {
										const value = "+1" + e.target.value.replace(/-/g, "").replace(/X/g, "");
										setFieldValue("phone", value);
									}}
									onBlur={handleBlur}
									size="lg"
									type="text"
									name="phone"
									placeholder="Company Phone Number"
									error={touched.phone && errors.phone ? `* ${errors.phone}` : ""}
								/>

								<LNFloatingInput
									size="lg"
									type="text"
									name="website"
									value={values.website}
									optional={true}
									onChange={(e) => {
										const value =
											"https://" + e.target.value.replace("https://", "").replace("http://", "");
										setFieldValue("website", value);
									}}
									onBlur={handleBlur}
									placeholder="Company Website"
									error={touched.website && errors.website ? `* ${errors.website}` : ""}
								/>
							</div>

							<div className="d-flex mt-5 buttons-wrapper">
								<div className="mr-3">
									<Link className="btn btn-white btn-lg btn-block" to="/">
										Save and Exit
									</Link>
								</div>

								<div className="flex-grow-1">
									<button
										type="submit"
										className="btn btn-primary btn-lg btn-block"
										onClick={handleSubmit}
										disabled={isSubmitting}>
										Next
									</button>
								</div>
							</div>
						</form>
					)}
				</Formik>
			</div>
		);
	}
}

// Prop Types
CompanyDetails.propTypes = {
	incrementfunction: PropTypes.func.isRequired,
};

export default CompanyDetails;
