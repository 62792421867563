import loanService from "../../services/loanService";

// Types
import {
	FETCH_REQUESTS_DATA_REQUEST,
	FETCH_REQUESTS_DATA_SUCCESS,
	FETCH_REQUESTS_DATA_FAILURE,
	FETCH_COMPLETED_LOANS_REQUEST,
	FETCH_COMPLETED_LOANS_SUCCESS,
	FETCH_COMPLETED_LOANS_FAILURE,
	FETCH_CURRENT_LOANS_REQUEST,
	FETCH_CURRENT_LOANS_SUCCESS,
	FETCH_CURRENT_LOANS_FAILURE,
	FETCH_PROCESSING_LOANS_REQUEST,
	FETCH_PROCESSING_LOANS_SUCCESS,
	FETCH_PROCESSING_LOANS_FAILURE,
	FETCH_COLLECTIONS_LOANS_REQUEST,
	FETCH_COLLECTIONS_LOANS_SUCCESS,
	FETCH_COLLECTIONS_LOANS_FAILURE,
	FETCH_DEFAULT_LOANS_REQUEST,
	FETCH_DEFAULT_LOANS_SUCCESS,
	FETCH_DEFAULT_LOANS_FAILURE,
	FETCH_MORE_COMPLETED_LOANS_REQUEST,
	FETCH_MORE_COMPLETED_LOANS_SUCCESS,
	FETCH_MORE_COMPLETED_LOANS_FAILURE,
	FETCH_MORE_CURRENT_LOANS_REQUEST,
	FETCH_MORE_CURRENT_LOANS_SUCCESS,
	FETCH_MORE_CURRENT_LOANS_FAILURE,
	FETCH_MORE_PROCESSING_LOANS_REQUEST,
	FETCH_MORE_PROCESSING_LOANS_SUCCESS,
	FETCH_MORE_PROCESSING_LOANS_FAILURE,
	FETCH_MORE_COLLECTIONS_LOANS_REQUEST,
	FETCH_MORE_COLLECTIONS_LOANS_SUCCESS,
	FETCH_MORE_COLLECTIONS_LOANS_FAILURE,
	FETCH_MORE_DEFAULT_LOANS_REQUEST,
	FETCH_MORE_DEFAULT_LOANS_SUCCESS,
	FETCH_MORE_DEFAULT_LOANS_FAILURE,
	FETCH_LOAN_PAYMENTS_BY_ID_REQUEST,
	FETCH_LOAN_PAYMENTS_BY_ID_SUCCESS,
	FETCH_LOAN_PAYMENTS_BY_ID_FAILURE,
	FETCH_CHECK_BALANCE_BY_ID_REQUEST,
	FETCH_CHECK_BALANCE_BY_ID_SUCCESS,
	FETCH_CHECK_BALANCE_BY_ID_FAILURE,
	FETCH_ENTERPRISE_FUND_BY_ID_REQUEST,
	FETCH_ENTERPRISE_FUND_BY_ID_SUCCESS,
	FETCH_ENTERPRISE_FUND_BY_ID_FAILURE,
	DISMISS_LOAN_REQUEST,
	DISMISS_LOAN_SUCCESS,
	DISMISS_LOAN_FAILURE,
	CANCEL_LOAN_REQUEST,
	CANCEL_LOAN_SUCCESS,
	CANCEL_LOAN_FAILURE,
} from "./types";

// Get Requests Data
export const fetchRequestsDataRequest = () => {
	return {
		type: FETCH_REQUESTS_DATA_REQUEST,
	};
};

export const fetchRequestsDataSuccess = (requests) => {
	return {
		type: FETCH_REQUESTS_DATA_SUCCESS,
		payload: requests,
	};
};

export const fetchRequestsDataFailure = (error) => {
	return {
		type: FETCH_REQUESTS_DATA_FAILURE,
		payload: error,
	};
};

export const fetchRequestsData = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchRequestsDataRequest());

			const { data } = await loanService.getRequests();
			dispatch(fetchRequestsDataSuccess(data));
		} catch (ex) {
			dispatch(fetchRequestsDataFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Get Completed Loans
export const fetchCompletedLoansRequest = () => {
	return {
		type: FETCH_COMPLETED_LOANS_REQUEST,
	};
};

export const fetchCompletedLoansSuccess = (loans) => {
	return {
		type: FETCH_COMPLETED_LOANS_SUCCESS,
		payload: loans,
	};
};

export const fetchCompletedLoansFailure = (error) => {
	return {
		type: FETCH_COMPLETED_LOANS_FAILURE,
		payload: error,
	};
};

export const fetchCompletedLoans = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchCompletedLoansRequest());

			const { data } = await loanService.getCompletedLoans();
			dispatch(fetchCompletedLoansSuccess(data));
		} catch (ex) {
			dispatch(fetchCompletedLoansFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Get More Completed Loans
export const fetchMoreCompletedLoansRequest = () => {
	return {
		type: FETCH_MORE_COMPLETED_LOANS_REQUEST,
	};
};

export const fetchMoreCompletedLoansSuccess = (loans) => {
	return {
		type: FETCH_MORE_COMPLETED_LOANS_SUCCESS,
		payload: loans,
	};
};

export const fetchMoreCompletedLoansFailure = (error) => {
	return {
		type: FETCH_MORE_COMPLETED_LOANS_FAILURE,
		payload: error,
	};
};

export const fetchMoreCompletedLoans = (apiEndpointForMoreLoans) => {
	return async function (dispatch) {
		try {
			dispatch(fetchMoreCompletedLoansRequest());

			const { data } = await loanService.getMoreCompletedLoans(apiEndpointForMoreLoans);
			dispatch(fetchMoreCompletedLoansSuccess(data));
		} catch (ex) {
			dispatch(fetchMoreCompletedLoansFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Get Current Loans
export const fetchCurrentLoansRequest = () => {
	return {
		type: FETCH_CURRENT_LOANS_REQUEST,
	};
};

export const fetchCurrentLoansSuccess = (loans) => {
	return {
		type: FETCH_CURRENT_LOANS_SUCCESS,
		payload: loans,
	};
};

export const fetchCurrentLoansFailure = (error) => {
	return {
		type: FETCH_CURRENT_LOANS_FAILURE,
		payload: error,
	};
};

export const fetchCurrentLoans = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchCurrentLoansRequest());

			const { data } = await loanService.getCurrentLoans();
			dispatch(fetchCurrentLoansSuccess(data));
		} catch (ex) {
			dispatch(fetchCurrentLoansFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Get More Current Loans
export const fetchMoreCurrentLoansRequest = () => {
	return {
		type: FETCH_MORE_CURRENT_LOANS_REQUEST,
	};
};

export const fetchMoreCurrentLoansSuccess = (loans) => {
	return {
		type: FETCH_MORE_CURRENT_LOANS_SUCCESS,
		payload: loans,
	};
};

export const fetchMoreCurrentLoansFailure = (error) => {
	return {
		type: FETCH_MORE_CURRENT_LOANS_FAILURE,
		payload: error,
	};
};

export const fetchMoreCurrentLoans = (apiEndpointForMoreLoans) => {
	return async function (dispatch) {
		try {
			dispatch(fetchMoreCurrentLoansRequest());

			const { data } = await loanService.getMoreCurrentLoans(apiEndpointForMoreLoans);
			dispatch(fetchMoreCurrentLoansSuccess(data));
		} catch (ex) {
			dispatch(fetchMoreCurrentLoansFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Get Processing Loans
export const fetchProcessingLoansRequest = () => {
	return {
		type: FETCH_PROCESSING_LOANS_REQUEST,
	};
};

export const fetchProcessingLoansSuccess = (loans) => {
	return {
		type: FETCH_PROCESSING_LOANS_SUCCESS,
		payload: loans,
	};
};

export const fetchProcessingLoansFailure = (error) => {
	return {
		type: FETCH_PROCESSING_LOANS_FAILURE,
		payload: error,
	};
};

export const fetchProcessingLoans = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchProcessingLoansRequest());

			const { data } = await loanService.getProcessingLoans();
			dispatch(fetchProcessingLoansSuccess(data));
		} catch (ex) {
			dispatch(fetchProcessingLoansFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Get More Processing Loans
export const fetchMoreProcessingLoansRequest = () => {
	return {
		type: FETCH_MORE_PROCESSING_LOANS_REQUEST,
	};
};

export const fetchMoreProcessingLoansSuccess = (loans) => {
	return {
		type: FETCH_MORE_PROCESSING_LOANS_SUCCESS,
		payload: loans,
	};
};

export const fetchMoreProcessingLoansFailure = (error) => {
	return {
		type: FETCH_MORE_PROCESSING_LOANS_FAILURE,
		payload: error,
	};
};

export const fetchMoreProcessingLoans = (apiEndpointForMoreLoans) => {
	return async function (dispatch) {
		try {
			dispatch(fetchMoreProcessingLoansRequest());

			const { data } = await loanService.getMoreProcessingLoans(apiEndpointForMoreLoans);
			dispatch(fetchMoreProcessingLoansSuccess(data));
		} catch (ex) {
			dispatch(fetchMoreProcessingLoansFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Get Collections Loans
export const fetchCollectionsLoansRequest = () => {
	return {
		type: FETCH_COLLECTIONS_LOANS_REQUEST,
	};
};

export const fetchCollectionsLoansSuccess = (loans) => {
	return {
		type: FETCH_COLLECTIONS_LOANS_SUCCESS,
		payload: loans,
	};
};

export const fetchCollectionsLoansFailure = (error) => {
	return {
		type: FETCH_COLLECTIONS_LOANS_FAILURE,
		payload: error,
	};
};

export const fetchCollectionsLoans = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchCollectionsLoansRequest());

			const { data } = await loanService.getCollectionsLoans();
			dispatch(fetchCollectionsLoansSuccess(data));
		} catch (ex) {
			dispatch(fetchCollectionsLoansFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Get More Collections Loans
export const fetchMoreCollectionsLoansRequest = () => {
	return {
		type: FETCH_MORE_COLLECTIONS_LOANS_REQUEST,
	};
};

export const fetchMoreCollectionsLoansSuccess = (loans) => {
	return {
		type: FETCH_MORE_COLLECTIONS_LOANS_SUCCESS,
		payload: loans,
	};
};

export const fetchMoreCollectionsLoansFailure = (error) => {
	return {
		type: FETCH_MORE_COLLECTIONS_LOANS_FAILURE,
		payload: error,
	};
};

export const fetchMoreCollectionsLoans = (apiEndpointForMoreLoans) => {
	return async function (dispatch) {
		try {
			dispatch(fetchMoreCollectionsLoansRequest());

			const { data } = await loanService.getMoreCollectionsLoans(apiEndpointForMoreLoans);
			dispatch(fetchMoreCollectionsLoansSuccess(data));
		} catch (ex) {
			dispatch(fetchMoreCollectionsLoansFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Get Default Loans
export const fetchDefaultLoansRequest = () => {
	return {
		type: FETCH_DEFAULT_LOANS_REQUEST,
	};
};

export const fetchDefaultLoansSuccess = (loans) => {
	return {
		type: FETCH_DEFAULT_LOANS_SUCCESS,
		payload: loans,
	};
};

export const fetchDefaultLoansFailure = (error) => {
	return {
		type: FETCH_DEFAULT_LOANS_FAILURE,
		payload: error,
	};
};

export const fetchDefaultLoans = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchDefaultLoansRequest());

			const { data } = await loanService.getDefaultLoans();
			dispatch(fetchDefaultLoansSuccess(data));
		} catch (ex) {
			dispatch(fetchDefaultLoansFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Get More Default Loans
export const fetchMoreDefaultLoansRequest = () => {
	return {
		type: FETCH_MORE_DEFAULT_LOANS_REQUEST,
	};
};

export const fetchMoreDefaultLoansSuccess = (loans) => {
	return {
		type: FETCH_MORE_DEFAULT_LOANS_SUCCESS,
		payload: loans,
	};
};

export const fetchMoreDefaultLoansFailure = (error) => {
	return {
		type: FETCH_MORE_DEFAULT_LOANS_FAILURE,
		payload: error,
	};
};

export const fetchMoreDefaultLoans = (apiEndpointForMoreLoans) => {
	return async function (dispatch) {
		try {
			dispatch(fetchMoreDefaultLoansRequest());

			const { data } = await loanService.getMoreDefaultLoans(apiEndpointForMoreLoans);
			dispatch(fetchMoreDefaultLoansSuccess(data));
		} catch (ex) {
			dispatch(fetchMoreDefaultLoansFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Fetch Loan Payments Data By ID
export const fetchLoanPaymentsDataByIdRequest = () => {
	return {
		type: FETCH_LOAN_PAYMENTS_BY_ID_REQUEST,
	};
};

export const fetchLoanPaymentsDataByIdSuccess = (paymentsData) => {
	return {
		type: FETCH_LOAN_PAYMENTS_BY_ID_SUCCESS,
		payload: paymentsData,
	};
};

export const fetchLoanPaymentsDataByIdFailure = (error) => {
	return {
		type: FETCH_LOAN_PAYMENTS_BY_ID_FAILURE,
		payload: error,
	};
};

export const fetchLoanPaymentsDataById = (id) => {
	return async function (dispatch) {
		try {
			dispatch(fetchLoanPaymentsDataByIdRequest());

			const { data: paymentsData } = await loanService.getLoanPaymentsById(id);
			dispatch(fetchLoanPaymentsDataByIdSuccess(paymentsData));
		} catch (ex) {
			dispatch(fetchLoanPaymentsDataByIdFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Check Balance By ID
export const fetchCheckBalanceByIdRequest = () => {
	return {
		type: FETCH_CHECK_BALANCE_BY_ID_REQUEST,
	};
};

export const fetchCheckBalanceByIdSuccess = (acceptedOffer) => {
	return {
		type: FETCH_CHECK_BALANCE_BY_ID_SUCCESS,
		payload: acceptedOffer,
	};
};

export const fetchCheckBalanceByIdFailure = (error) => {
	return {
		type: FETCH_CHECK_BALANCE_BY_ID_FAILURE,
		payload: error,
	};
};

export const fetchCheckBalanceById = (id) => {
	return async function (dispatch) {
		try {
			dispatch(fetchCheckBalanceByIdRequest());

			const { data: acceptedOffer } = await loanService.checkBalanceById(id);
			dispatch(fetchCheckBalanceByIdSuccess(acceptedOffer));
		} catch (ex) {
			console.log("Error: ", ex);

			if (ex.response.data) {
				dispatch(fetchCheckBalanceByIdFailure(ex.response.data));
			}
		}
	};
};

// Enterprise Fund By ID
export const fetchEnterpriseFundByIdRequest = () => {
	return {
		type: FETCH_ENTERPRISE_FUND_BY_ID_REQUEST,
	};
};

export const fetchEnterpriseFundByIdSuccess = (loan) => {
	return {
		type: FETCH_ENTERPRISE_FUND_BY_ID_SUCCESS,
		payload: loan,
	};
};

export const fetchEnterpriseFundByIdFailure = (error) => {
	return {
		type: FETCH_ENTERPRISE_FUND_BY_ID_FAILURE,
		payload: error,
	};
};

export const fetchEnterpriseFundById = (id, data) => {
	return async function (dispatch) {
		try {
			dispatch(fetchEnterpriseFundByIdRequest());

			const { data: loan } = await loanService.enterpriseFundById(id, data);
			dispatch(fetchEnterpriseFundByIdSuccess(loan));
		} catch (ex) {
			dispatch(fetchEnterpriseFundByIdFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Dismiss Loan
export const dismissLoanRequest = () => {
	return {
		type: DISMISS_LOAN_REQUEST,
	};
};

export const dismissLoanSuccess = (loanId) => {
	return {
		type: DISMISS_LOAN_SUCCESS,
		loanId: loanId,
	};
};

export const dismissLoanFailure = (error) => {
	return {
		type: DISMISS_LOAN_FAILURE,
		payload: error,
	};
};

export const dismissLoan = (id, data) => {
	return async function (dispatch) {
		try {
			dispatch(dismissLoanRequest());

			await loanService.dismissLoan(id, data);
			dispatch(dismissLoanSuccess(id));
		} catch (ex) {
			dispatch(dismissLoanFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Cancel Loan
export const cancelLoanRequest = () => {
	return {
		type: CANCEL_LOAN_REQUEST,
	};
};

export const cancelLoanSuccess = (loanId) => {
	return {
		type: CANCEL_LOAN_SUCCESS,
		loanId: loanId,
	};
};

export const cancelLoanFailure = (error) => {
	return {
		type: CANCEL_LOAN_FAILURE,
		payload: error,
	};
};

export const cancelLoan = (id, data) => {
	return async function (dispatch) {
		try {
			dispatch(cancelLoanRequest());

			await loanService.cancelLoan(id, data);
			dispatch(cancelLoanSuccess(id));
		} catch (ex) {
			dispatch(cancelLoanFailure(ex));
			console.log("Error: ", ex);
		}
	};
};
