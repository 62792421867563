import React from "react";
import propTypes from "prop-types";
import moment from "moment";

// Components
import LNAvatar from "../common/LNAvatar/LNAvatar";

// CSS
import "./feedItem.css";

function FeedItem({ item }) {
	// Helper Methods
	const isMoreThanOneDayAgo = () => {
		const a = moment();
		const b = moment(item.created);
		const diff = a.diff(b, "days");

		if (diff > 0) return false;
		return true;
	};

	return (
		<div className="feed-item d-flex">
			<div className="mr-3">
				<LNAvatar data={[{ icon: item.lender.avatar, color: 4 }]} small={true} />
			</div>

			<div>
				<div className="font-weight-bold small mb-3 mt-2">
					<span>{item.lender.initial}</span> invested $<span>{Math.floor(item.loan.loan_amount)}</span> for{" "}
					<span>{item.loan.payment_terms}</span> month
				</div>

				<div className="small">
					Interest rate: <span className="font-weight-bold">{parseInt(item.loan.interest_rate * 100)}</span>%
				</div>
				<div className="small">
					Expected earning: $<span className="font-weight-bold">{item.loan.loan_return}</span>
				</div>

				<div className="d-flex align-items-center mt-2">
					{isMoreThanOneDayAgo() && (
						<h6>
							<span className="badge badge-secondary">New</span>
						</h6>
					)}

					<div className="ml-auto time">{moment(item.created).fromNow()}</div>
				</div>
			</div>
		</div>
	);
}

FeedItem.propTypes = {
	item: propTypes.object.isRequired,
};

export default FeedItem;
