import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
	fetchAggregationData,
	fetchCurrentBalance,
	fetchAverageRateOfReturn,
	fetchCurrentLoan,
	fetchTotalInvested,
	fetchTotalEarned,
} from "../../redux";

// Utils
import { handleBalanceFormat } from "../../utils/handleBalanceFormat";

// Components
import Filter from "../filter/filter";
import FeedBar from "../feedBar/feedBar";
import Requests from "../requests/requests";
import LikedRequests from "../likedRequests/likedRequests";
// import InvestorGraph from "../investorGraph/investorGraph";
import LNModal from "../common/LNModal/LNModal";
import PopupHeader from "../common/popupHeader/popupHeader";

// Images
import FilterIcon from "../../img/icons/blue/filter-icon.svg";
import DashboardLoadingBlock from "../../img/spinners/blocks/dashboard page.svg";
import Info from "../../img/icons/muted/info.svg";
import InfoWhite from "../../img/icons/white/info.svg";

// CSS
import "./dashboardPage.css";

class DashboardPage extends Component {
	state = {
		liked: false,
		currentBalance: 0,
		currentBalanceSign: "",
		gainAndLoss: "+$0.00",
		totalLoan: 0,
		pendingAmount: 0,
		paidAmount: 0,
		renderGraphOf: "desktop",
		modal: {
			balance: {
				visible: false,
			},
		},
	};

	// Calculate Window Width
	calculateWindowWidth = () => {
		const { innerWidth } = window;
		if (innerWidth >= 769) {
			this.setState({ renderGraphOf: "desktop" });
		} else if (innerWidth >= 576) {
			this.setState({ renderGraphOf: "tablet" });
		} else if (innerWidth >= 0) {
			this.setState({ renderGraphOf: "mobile" });
		}
	};

	// When Mount
	async componentDidMount() {
		// Specify which graph to render
		this.calculateWindowWidth();
		window.addEventListener("resize", this.calculateWindowWidth);

		// Get data
		this.props.fetchAggregationData();
		this.props.fetchCurrentBalance();
		this.props.fetchAverageRateOfReturn();
		this.props.fetchCurrentLoan();
		this.props.fetchTotalInvested();
		this.props.fetchTotalEarned();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.calculateWindowWidth);
	}

	// Helper Methods
	handleShowFilter = () => {
		const filterElement = document.getElementById("dashboardFilter");

		if (filterElement.classList.contains("d-none")) {
			filterElement.classList.remove("d-none");
		} else {
			filterElement.classList.add("d-none");
		}
	};

	updateCurrentBalance = (selectedValue, firstValue) => {
		const { investment_capital } = this.props.aggregationData.data;

		function handleSignOperator(number, showPositiveSign) {
			const sign = Math.sign(number);
			switch (sign) {
				case showPositiveSign && 0:
				case showPositiveSign && 1:
					return "+";
				case -0:
				case -1:
					return "-";
				case "Nan":
				default:
					return "";
			}
		}

		// Balance
		const balance = selectedValue + investment_capital;
		const firstPointBalance = firstValue + investment_capital;

		// Calculate gain and loss values
		const gainAndLossValue = balance - firstPointBalance;

		// Assign balance and gain/loss values
		this.setState({
			currentBalance: handleBalanceFormat(Math.abs(balance)),
			currentBalanceSign: handleSignOperator(selectedValue + investment_capital),
			gainAndLoss: `${handleSignOperator(gainAndLossValue, true)}$${handleBalanceFormat(
				Math.abs(gainAndLossValue)
			)}`,
		});
	};

	updateCurrentInterval = (value) => {
		const { OneMonth, ThreeMonth, SixMonth, OneYear, AllData } = this.props.aggregationData.data;

		switch (value) {
			case "1M":
				this.setState({
					totalLoan: OneMonth.total_loan_count,
					pendingAmount: AllData.total_pending,
					paidAmount: OneMonth.total_paid,
				});
				break;

			case "3M":
				this.setState({
					totalLoan: ThreeMonth.total_loan_count,
					pendingAmount: AllData.total_pending,
					paidAmount: ThreeMonth.total_paid,
				});
				break;

			case "6M":
				this.setState({
					totalLoan: SixMonth.total_loan_count,
					pendingAmount: AllData.total_pending,
					paidAmount: SixMonth.total_paid,
				});
				break;

			case "1Y":
				this.setState({
					totalLoan: OneYear.total_loan_count,
					pendingAmount: AllData.total_pending,
					paidAmount: OneYear.total_paid,
				});
				break;

			case "All":
				this.setState({
					totalLoan: AllData.total_loan_count,
					pendingAmount: AllData.total_pending,
					paidAmount: AllData.total_paid,
				});
				break;

			default:
				break;
		}
	};

	showBalanceModal = () => {
		const modal = { ...this.state.modal };
		modal.balance.visible = true;
		this.setState({ modal });
	};

	hideBalanceModal = () => {
		const modal = { ...this.state.modal };
		modal.balance.visible = false;
		this.setState({ modal });
	};

	render() {
		const { totalLoan, pendingAmount, paidAmount, modal } = this.state;
		const { loansType } = this.props.match.params;
		const {
			aggregationData,
			currentBalance,
			averageRateOfReturn,
			currentLoan,
			totalInvested,
			totalEarned,
		} = this.props;

		// Check if loading
		if (
			aggregationData.loading ||
			currentBalance.loading ||
			averageRateOfReturn.loading ||
			currentLoan.loading ||
			totalInvested.loading ||
			totalEarned.loading
		) {
			return (
				<div className="dashboard-wrapper">
					<div className="dashboard-content">
						<div>
							<img src={DashboardLoadingBlock} alt="Loading" className="w-100" />
						</div>
					</div>
				</div>
			);
		}

		// Render
		return (
			<div className="dashboard-wrapper">
				{/* Content */}
				<div className="dashboard-content">
					{/* Headers */}
					<div className="headers">
						{/* Desktop */}
						<div className="d-none d-md-block analysis-card-desktop">
							<div className="card p-3">
								<div className="pt-4">
									<div className="row">
										<div className="col-5">
											<div className="label">
												Balance
												<img
													src={Info}
													alt="Info"
													onClick={this.showBalanceModal}
													className="pointer p-1"
													width="20"
												/>
											</div>
											<div className="current-balance">
												<span>{this.state.currentBalanceSign}</span>
												<span>$</span>
												<span>{this.state.currentBalance}</span>
											</div>
											<div className="label">{this.state.gainAndLoss}</div>
										</div>

										{/* <div className="col-7">
											{renderGraphOf === "desktop" && (
												<InvestorGraph
													updateCurrentBalance={this.updateCurrentBalance}
													updateCurrentInterval={this.updateCurrentInterval}
												/>
											)}
										</div> */}
									</div>
								</div>

								<div className="mt-3">
									<div className="row">
										<div className="col-3 border-right">
											<div>
												<div className="label">Total loans</div>
												<div className="value">
													{totalLoan}
													<Link to="/user/investment" className="ml-2">
														&gt;
													</Link>
												</div>
											</div>
										</div>
										<div className="col-3 border-right">
											<div>
												<div className="label">Pending amount</div>
												<div className="value">${pendingAmount}</div>
											</div>
										</div>
										<div className="col-3 border-right">
											<div>
												<div className="label">Paid amount</div>
												<div className="value">${paidAmount}</div>
											</div>
										</div>
										<div className="col-3">
											<div>
												<div className="label">Lenme cash</div>
												<div className="value">
													${currentBalance.data.balance}
													<Link to="/user/bank-transfer" className="ml-2">
														&gt;
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Tablet */}
						<div className="d-none d-sm-block d-md-none analysis-card-tablet">
							{/* Background */}
							<div className="background"></div>

							{/* Data */}
							<div>
								<div className="row">
									<div className="col-4 pt-3">
										<div className="label">
											Balance
											<img
												src={InfoWhite}
												alt="Info"
												onClick={this.showBalanceModal}
												className="pointer p-1"
												width="20"
											/>
										</div>
										<div className="current-balance">
											<span>{this.state.currentBalanceSign}</span>
											<span>$</span>
											<span>{this.state.currentBalance}</span>
										</div>
										<div className="label">{this.state.gainAndLoss}</div>
									</div>

									{/* <div className="col-8">
										<div className="card px-4 pt-4 pb-2">
											{renderGraphOf === "tablet" && (
												<InvestorGraph
													updateCurrentBalance={this.updateCurrentBalance}
													updateCurrentInterval={this.updateCurrentInterval}
												/>
											)}
										</div>
									</div> */}
								</div>

								<div className="mt-4">
									<div className="row">
										<div className="col-3 border-right">
											<div>
												<div className="label">Total loans</div>
												<div className="value">
													{totalLoan}
													<Link to="/user/investment" className="ml-2">
														&gt;
													</Link>
												</div>
											</div>
										</div>
										<div className="col-3 border-right">
											<div>
												<div className="label">Pending amount</div>
												<div className="value">${pendingAmount}</div>
											</div>
										</div>
										<div className="col-3 border-right">
											<div>
												<div className="label">Paid amount</div>
												<div className="value">${paidAmount}</div>
											</div>
										</div>
										<div className="col-3">
											<div>
												<div className="label">Lenme cash</div>
												<div className="value">
													${currentBalance.data.balance}
													<Link to="/user/bank-transfer" className="ml-2">
														&gt;
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Mobile */}
						<div className="d-sm-none analysis-card-mobile">
							{/* Background */}
							<div className="background"></div>

							{/* Data */}
							<div>
								<div className="pt-3 balance-container position-relative">
									<div className="label">
										Balance
										<img
											src={InfoWhite}
											alt="Info"
											onClick={this.showBalanceModal}
											className="pointer p-1"
											width="20"
										/>
									</div>
									<div className="current-balance">
										<span>{this.state.currentBalanceSign}</span>
										<span>$</span>
										<span>{this.state.currentBalance}</span>
									</div>
									<div className="label">{this.state.gainAndLoss}</div>
								</div>

								{/* <div className="pt-3">
									<div className="card px-4 pt-4 pb-2">
										{renderGraphOf === "mobile" && (
											<InvestorGraph
												updateCurrentBalance={this.updateCurrentBalance}
												updateCurrentInterval={this.updateCurrentInterval}
											/>
										)}
									</div>
								</div> */}

								<div className="pt-3">
									<div className="row">
										<div className="col-4">
											<div>
												<div className="label">Total loans</div>
												<div className="value">
													{totalLoan}
													<Link to="/user/investment" className="ml-2">
														&gt;
													</Link>
												</div>
											</div>
										</div>
										<div className="col-4">
											<div>
												<div className="label">Pending amount</div>
												<div className="value">${pendingAmount}</div>
											</div>
										</div>
										<div className="col-4">
											<div>
												<div className="label">Paid amount</div>
												<div className="value">${paidAmount}</div>
											</div>
										</div>
										<div className="col-12 border-top mt-3 py-3">
											<div className="lenme-cash d-flex justify-content-between">
												<div className="label">Lenme cash</div>
												<div className="value">
													${currentBalance.data.balance}
													<Link to="/user/bank-transfer" className="ml-2">
														&gt;
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row mobile-no-gutters mt-3 mt-sm-5">
						{/* Filter */}
						<div
							className="dashboard-filter col-12 col-sm-5 col-md-4 pr-0 d-none d-sm-block"
							id="dashboardFilter">
							<Filter handleShowFilter={this.handleShowFilter} />
						</div>

						{/* Cards */}
						<div className="col-12 col-sm-7 col-md-8 dashboard-nav-tabs mb-3">
							{/* Filter Icon */}
							<div className="filter-icon shadow d-sm-none" onClick={this.handleShowFilter}>
								<img src={FilterIcon} alt="Filter" />
							</div>

							{/* Nav */}
							<div className="d-block d-sm-block">
								<div className="params-nav">
									<NavLink to="/user/dashboard" exact>
										Requests
									</NavLink>
									<NavLink to="/user/dashboard/liked">Liked</NavLink>
								</div>
							</div>

							{loansType === undefined && <Requests />}
							{loansType === "liked" && <LikedRequests />}
						</div>
					</div>
				</div>

				{/* Feed Bar */}
				<FeedBar />

				{/* Balance Modal */}
				<LNModal show={modal.balance.visible} onHide={this.hideBalanceModal}>
					<PopupHeader title="Balance" handleBackClick={this.hideBalanceModal} />

					<div className="modal-left-space-container">
						<div className="mt-5 font-size-14">
							Your balance represents your performance till the selected date. The balance helps you
							understand your balance including profit, cash In and cash out.
						</div>

						<div className="modal-buttons-wrapper vertical">
							<button onClick={this.hideBalanceModal} className="btn btn-black btn-block btn-lg">
								Got it
							</button>
						</div>
					</div>
				</LNModal>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		aggregationData: state.user.aggregationData,
		currentBalance: state.user.currentBalance,
		averageRateOfReturn: state.user.averageRateOfReturn,
		currentLoan: state.user.currentLoan,
		totalInvested: state.user.totalInvested,
		totalEarned: state.user.totalEarned,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchAggregationData: () => dispatch(fetchAggregationData()),
		fetchCurrentBalance: () => dispatch(fetchCurrentBalance()),
		fetchAverageRateOfReturn: () => dispatch(fetchAverageRateOfReturn()),
		fetchCurrentLoan: () => dispatch(fetchCurrentLoan()),
		fetchTotalInvested: () => dispatch(fetchTotalInvested()),
		fetchTotalEarned: () => dispatch(fetchTotalEarned()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
