import React from "react";
import PropTypes from "prop-types";

// CSS
import "./LNRadioInput.css";

const LNRadioInput = ({ label, subLabel, name, value, onChange, disabled, defaultChecked }) => {
	// Render
	return (
		<div className="custom-control custom-radio">
			<input
				type="radio"
				name={name}
				className="custom-control-input"
				id={value}
				onChange={onChange}
				value={value}
				disabled={disabled}
				defaultChecked={defaultChecked}
			/>
			<label className={`custom-control-label`} htmlFor={value}>
				{label}
			</label>
			{subLabel && <p className="font-size-14 text-muted">{subLabel}</p>}
		</div>
	);
};

// Prop Types
LNRadioInput.propTypes = {
	label: PropTypes.string,
	subLabel: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	defaultChecked: PropTypes.bool,
};

export default LNRadioInput;
