// Fetch User Data
export const FETCH_USER_DATA_REQUEST = "FETCH_USER_DATA_REQUEST";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_FAILURE = "FETCH_USER_DATA_FAILURE";

// Fetch Company Data
export const FETCH_USER_COMPANY_REQUEST = "FETCH_USER_COMPANY_REQUEST";
export const FETCH_USER_COMPANY_SUCCESS = "FETCH_USER_COMPANY_SUCCESS";
export const FETCH_USER_COMPANY_FAILURE = "FETCH_USER_COMPANY_FAILURE";

// Fetch License Data
export const FETCH_USER_LICENSE_REQUEST = "FETCH_USER_LICENSE_REQUEST";
export const FETCH_USER_LICENSE_SUCCESS = "FETCH_USER_LICENSE_SUCCESS";
export const FETCH_USER_LICENSE_FAILURE = "FETCH_USER_LICENSE_FAILURE";

// Fetch Owners Data
export const FETCH_USER_OWNERS_REQUEST = "FETCH_USER_OWNERS_REQUEST";
export const FETCH_USER_OWNERS_SUCCESS = "FETCH_USER_OWNERS_SUCCESS";
export const FETCH_USER_OWNERS_FAILURE = "FETCH_USER_OWNERS_FAILURE";

// Update User Profile
export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

// Update Company Profile
export const UPDATE_USER_COMPANY_REQUEST = "UPDATE_USER_COMPANY_REQUEST";
export const UPDATE_USER_COMPANY_SUCCESS = "UPDATE_USER_COMPANY_SUCCESS";
export const UPDATE_USER_COMPANY_FAILURE = "UPDATE_USER_COMPANY_FAILURE";

// Add New Owner
export const ADD_USER_OWNER_REQUEST = "ADD_USER_OWNER_REQUEST";
export const ADD_USER_OWNER_SUCCESS = "ADD_USER_OWNER_SUCCESS";
export const ADD_USER_OWNER_FAILURE = "ADD_USER_OWNER_FAILURE";

// Fetch Aggregation Data
export const FETCH_AGGREGATION_DATA_REQUEST = "FETCH_AGGREGATION_DATA_REQUEST";
export const FETCH_AGGREGATION_DATA_SUCCESS = "FETCH_AGGREGATION_DATA_SUCCESS";
export const FETCH_AGGREGATION_DATA_FAILURE = "FETCH_AGGREGATION_DATA_FAILURE";

// Fetch Current Balance
export const FETCH_CURRENT_BALANCE_REQUEST = "FETCH_CURRENT_BALANCE_REQUEST";
export const FETCH_CURRENT_BALANCE_SUCCESS = "FETCH_CURRENT_BALANCE_SUCCESS";
export const FETCH_CURRENT_BALANCE_FAILURE = "FETCH_CURRENT_BALANCE_FAILURE";

// Fetch Average Rate Of Return
export const FETCH_AVERAGE_RATE_OF_RETURN_REQUEST = "FETCH_AVERAGE_RATE_OF_RETURN_REQUEST";
export const FETCH_AVERAGE_RATE_OF_RETURN_SUCCESS = "FETCH_AVERAGE_RATE_OF_RETURN_SUCCESS";
export const FETCH_AVERAGE_RATE_OF_RETURN_FAILURE = "FETCH_AVERAGE_RATE_OF_RETURN_FAILURE";

// Fetch Current Loan
export const FETCH_CURRENT_LOAN_REQUEST = "FETCH_CURRENT_LOAN_REQUEST";
export const FETCH_CURRENT_LOAN_SUCCESS = "FETCH_CURRENT_LOAN_SUCCESS";
export const FETCH_CURRENT_LOAN_FAILURE = "FETCH_CURRENT_LOAN_FAILURE";

// Fetch Total Invested
export const FETCH_TOTAL_INVESTED_REQUEST = "FETCH_TOTAL_INVESTED_REQUEST";
export const FETCH_TOTAL_INVESTED_SUCCESS = "FETCH_TOTAL_INVESTED_SUCCESS";
export const FETCH_TOTAL_INVESTED_FAILURE = "FETCH_TOTAL_INVESTED_FAILURE";

// Fetch Total Earned
export const FETCH_TOTAL_EARNED_REQUEST = "FETCH_TOTAL_EARNED_REQUEST";
export const FETCH_TOTAL_EARNED_SUCCESS = "FETCH_TOTAL_EARNED_SUCCESS";
export const FETCH_TOTAL_EARNED_FAILURE = "FETCH_TOTAL_EARNED_FAILURE";

// Money Made Auth
export const FETCH_MONEY_MADE_REQUEST = "FETCH_MONEY_MADE_REQUEST";
export const FETCH_MONEY_MADE_SUCCESS = "FETCH_MONEY_MADE_SUCCESS";
export const FETCH_MONEY_MADE_FAILURE = "FETCH_MONEY_MADE_FAILURE";
