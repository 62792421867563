// Types
import { SHOW_TOAST, HIDE_TOAST } from "./types";

// Initial State
const initialState = {
	toast: [],
	apiVersion:"2"
};

// Reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {
		// Toast
		case SHOW_TOAST:
			return {
				...state,
				toast: [...state.toast, action.payload],
			};
		case HIDE_TOAST:
			return {
				...state,
				toast: state.toast.filter((item) => item !== state.toast[action.index]),
			};

		default:
			return state;
	}
};

export default reducer;
