import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFavLoanRequests, fetchMoreFavLoanRequests, fetchClearFavLoanRequests } from "../../redux";

// Bootstrap Components
import { Modal } from "react-bootstrap";

// Components
import RequestCard from "../requestCard/requestCard";
import ShowMore from "../common/showMore/showMore";

// Images
import RequestsEmpty from "../../img/static/requests_empty.svg";
import LoanCardsLoadingBlocks from "../../img/spinners/blocks/loan cards.svg";

// Import CSS
import "./likedRequests.css";

function LikedRequests() {
	const [modalVisible, setModalVisible] = useState(false);
	const loanRequests = useSelector((state) => state.dashboard.favLoanRequests);
	const { results: requests = [] } = loanRequests.data;
	const dispatch = useDispatch();

	// When Mount
	useEffect(() => {
		dispatch(fetchFavLoanRequests());

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Helper Methods
	const handleOpenModal = () => {
		setModalVisible(true);
	};

	const handleCloseModal = () => {
		setModalVisible(false);
	};

	const clearAllFavLoans = () => {
		dispatch(fetchClearFavLoanRequests());
		handleCloseModal();
	};

	// Check if loading
	if (loanRequests.loading) {
		return (
			<div>
				<img src={LoanCardsLoadingBlocks} alt="Loading..." className="w-100" />
			</div>
		);
	}

	// Zero State
	if (requests.length === 0) {
		return (
			<div className="requests-zero-state mt-3">
				<div>We were unable to find any results.</div>
				<div>Please adjust your search.</div>
				<img className="pt-5" src={RequestsEmpty} alt="No Requests Found" />
			</div>
		);
	}

	// Render
	return (
		<div>
			<div className="d-flex justify-content-end">
				<div className="clear-all-favs p-2 mt-3 pointer" onClick={handleOpenModal}>
					Clear all
				</div>
			</div>

			{requests.map((item) => (
				<RequestCard key={item.id} data={item} />
			))}

			{loanRequests.data.next && (
				<ShowMore
					loading={loanRequests.paginate.loading}
					handleClick={() => dispatch(fetchMoreFavLoanRequests())}
				/>
			)}

			{/* Clear Fav Loans Modal */}
			<Modal show={modalVisible} onHide={handleCloseModal} dialogClassName="bank-transfer-modal">
				<Modal.Body>
					<div className="d-flex align-items-center flex-wrap position-relative p-3">
						<div className="modal-close pointer" onClick={handleCloseModal}>
							Close
						</div>

						<div className="bank-transfer-link-success-title p-3 flex-grow-1 w-100">
							Clear favorites list!?
						</div>

						<div className="p-3">Your list of favorite loan requests will be permanantly deleted!</div>

						<div className="w-100 justify-content-center d-flex p-3">
							<div className="btn btn-lg text-danger flex-grow-1" onClick={handleCloseModal}>
								Cancel
							</div>

							<div className="btn btn-lg btn-black flex-grow-1" onClick={clearAllFavLoans}>
								Clear
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default LikedRequests;
