import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
// import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";

// Import Services
import auth from "../../services/authService";
import { register } from "../../services/signupService";
// Import Components
import LNFloatingInput from "../common/LNFloatingInput/LNFloatingInput";

// Import Images
import Logo from "../../img/assets/logo.svg";

// Import CSS
import "./signUpPage.css";

class SignUpPage extends Component {
	state = {
		loading: true,
		data: {
			companyName: "",
			email: "",
			password: "",
			confirmPassword: "",
		},
		error: false,
		errorMessages: [],
	};

	// Get Account
	populateAccount() {
		const account = auth.getCurrentAccount();
		this.setState({ account });
	}

	// Handle Loading
	handleLoading() {
		const loading = false;
		this.setState({ loading });
	}

	// When Mount
	componentDidMount() {
		// Get account
		this.populateAccount();

		// End loading
		this.handleLoading();
	}

	// Render
	render() {
		const { loading, account, data } = this.state;

		// Check if account
		if (account) return <Redirect to="/" />;

		// Validations
		const ValidationSchema = Yup.object().shape({
			companyName: Yup.string()
				.min(1, "Too Short!")
				.max(255, "Too Long!")
				.required("Required field"),
			email: Yup.string()
				.email("Please enter a valid email address.")
				.max(255, "Too Long!")
				.required("Required field"),
			password: Yup.string()
				.min(8, "Password must be atleast 8 characters.")
				.max(255, "Too Long!")
				.matches(
					/(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W)/,
					"Password must contain atleast one uppercase letter, one lowercase letter, one number and one special character."
				)
				.required("Required field."),
			confirmPassword: Yup.string()
				.min(1, "Too Short!")
				.max(255, "Too Long!")
				.oneOf([Yup.ref("password"), null], "Passwords must match")
				.required("Required field"),
		});

		// Check if loading
		if (loading) return <div>Loading...</div>;

		// Render
		return (
			<div className="bg-landing">
				<div className="container">
					<div className="row no-gutters min-vh-100 justify-content-center align-items-center">
						<div className="col-12 col-md-10 col-lg-7 col-xl-6">
							<div className="pre-login-form bg-white shadow my-3">
								<div className="mb-3 logo">
									<img src={Logo} alt="Logo" />
								</div>
								<div className="mb-3 title">Welcome to Lenme Enterprise</div>
								<div className="mb-2 d-flex">
									<div className="font-weight-bold flex-grow-1">Sign up</div>
									<div>
										<Link to="/">Sign In</Link>
									</div>
								</div>

								<div className="mb-2">
									<Formik
										initialValues={data}
										validationSchema={ValidationSchema}
										onSubmit={async (values, { setSubmitting, setErrors }) => {
											try {
												setSubmitting(true);
												await register(values);
												this.setState({
													error: false,
												});

												setSubmitting(false);
												window.location = "/signup-process";
											} catch (error) {
												console.log("Error: ", error);

												this.setState({
													error: true,
													errorMessages: error.response.data,
												});

												setErrors(this.state.errorMessages);
												setSubmitting(false);
											}
										}}>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
										}) => (
											<form onSubmit={handleSubmit}>
												<LNFloatingInput
													size="lg"
													type="text"
													optional={false}
													name="companyName"
													value={values.companyName}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="Company Name"
													error={
														touched.companyName && errors.companyName
															? `* ${errors.companyName}`
															: ""
													}
												/>

												<LNFloatingInput
													size="lg"
													type="email"
													optional={false}
													name="email"
													value={values.email}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="Company Email"
													error={touched.email && errors.email ? `* ${errors.email}` : ""}
												/>

												<LNFloatingInput
													size="lg"
													type="password"
													optional={false}
													name="password"
													value={values.password}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="Password"
													error={
														touched.password && errors.password
															? `* ${errors.password}`
															: ""
													}
												/>

												<LNFloatingInput
													size="lg"
													type="password"
													optional={false}
													name="confirmPassword"
													value={values.confirmPassword}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="Confirm Password"
													error={
														touched.confirmPassword && errors.confirmPassword
															? `* ${errors.confirmPassword}`
															: ""
													}
												/>

												<button
													className="btn btn-primary btn-lg btn-block mt-4"
													type="submit"
													disabled={isSubmitting}
													onClick={handleSubmit}>
													Next
												</button>
											</form>
										)}
									</Formik>
								</div>
								<div className="font-size-14">
									By clicking "Next" I agree to Lenme's{" "}
									<a
										target="_blank"
										className="text-dark"
										rel="noopener noreferrer"
										href="https://lenmeenterprise.com/terms-of-use/">
										Terms of Service
									</a>{" "}
									and{" "}
									<a
										target="_blank"
										className="text-dark"
										rel="noopener noreferrer"
										href="https://lenmeenterprise.com/privacy-policy/">
										Privacy Policy
									</a>
									.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

// Prop Types
SignUpPage.propTypes = {};

export default SignUpPage;
