import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchUserData } from "../../redux";

// Import Services
import userService from "../../services/userService";
import fundingService from "../../services/fundingService";

// Import Utils
import { handleBalanceFormat } from "../../utils/handleBalanceFormat";

// Import Bootstrap Components
import { Modal } from "react-bootstrap";

// Import Components
import LNInput from "../common/LNInput/LNInput";
import ResponseIllustration from "../common/responseIllustration/responseIllustration";
import BankProviders from "../bankProviders/bankProviders";
import MobileNav from "../mobileNav/mobileNav";

// Import Images
import ZeroStateImage from "../../img/static/bank-transfer-zero-state.svg";
import AddIcon from "../../img/icons/blue/add-icon.svg";
import BankTransferLoadingBlock from "../../img/spinners/blocks/bank transfer page.svg";

// Import CSS
import "./bankTransferPage.css";

class BankTransferPage extends Component {
	state = {
		loading: true,
		modal: {
			fastlinkSuccess: {
				visible: false,
			},
			addingFunds: {
				visible: false,
			},
			transferringFunds: {
				visible: false,
			},
			transferSuccess: {
				visible: false,
			},
		},
		data: {
			transfer_amount: "",
			add_amount: "",
		},
		showProviders: false,
		balance: 0,
		fundingResources: null,
		primaryFundingResource: null,
		transferMessage: null,
		hasBanks: false,
		error: {
			error: false,
			message: null,
		},
	};

	// Get User Balance
	async getUserBalance() {
		try {
			const { data } = await userService.getUserBalance();
			this.setState({ balance: data.balance });
		} catch (ex) {
			const error = {
				error: true,
				message: ex.response.data.detail,
			};
			this.setState({ error });
		}
	}

	// Get User Funding Resources If Has Dwolla Customer Flag
	async getUserFundingResourcesIfHasDwollaCustomerFlag() {
		if (!this.props.userData.user.is_dwolla_customer) return;

		try {
			const { data } = await fundingService.getUserFundingResources();
			const hasBanks = data.results.length > 0 ? true : false;
			this.setState({ hasBanks, fundingResources: data.results });

			const primaryFundingResource = data.results.find((resource) => resource.is_primary);
			this.setState({ primaryFundingResource });

			// if (!primaryFundingResource) this.populateFavouriteProviders();
		} catch (ex) {
			const error = {
				error: true,
				message: ex.response.data.detail,
			};
			this.setState({ error });
		}
	}

	// Handle Loading
	handleLoading() {
		this.setState({ loading: false });
	}

	// When Mount
	async componentDidMount() {
		// Get user data
		await this.props.fetchUserData();

		// Get User balance
		await this.getUserBalance();

		// Get User funding resources if has dwolla customer flag
		await this.getUserFundingResourcesIfHasDwollaCustomerFlag();

		// Handle loading
		this.handleLoading();
	}

	// Helper Methods
	handleOpenModal = (name) => {
		const modal = { ...this.state.modal };
		modal[name].visible = true;
		this.setState({ modal });
	};

	handleCloseModal = (name) => {
		const modal = { ...this.state.modal };
		modal[name].visible = false;
		if (name === "addingFunds" || name === "transferringFunds") {
			let data = { ...this.state.data };
			Object.keys(data).forEach((x) => (data[x] = null));
			this.setState({ data });
		}
		this.setState({ modal });
	};

	// Controlled change for add and transfer money value inputs
	handleChange = ({ currentTarget: input, keyCode }) => {
		// Numbers Only and decimals with 2 points max
		const regex = /^[0-9]+([.]{0,1})([0-9]){0,2}?$/g;

		if (input.value === "" || regex.test(input.value)) {
			// If number and transferring, max is balance
			if (input.value !== "" && input.name === "transfer_amount" && input.value > this.state.balance) {
				input.value = this.state.balance;
			}

			// Max is 10000
			if (input.value !== "" && input.value > 10000) {
				input.value = 10000;
			}
			const data = { ...this.state.data };
			data[input.name] = input.value;
			this.setState({ data });
		}
	};

	// Transfer amount input in transfer modal to primary bank
	async transferToBank() {
		try {
			const transfer = await fundingService.transferBalanceToBank(this.state.data.transfer_amount);
			this.setState({ transferMessage: transfer.data.status });
			this.handleCloseModal("transferringFunds");
			this.handleOpenModal("transferSuccess");

			// Refresh User balance
			const userBalance = await userService.getUserBalance();
			const error = {
				error: false,
			};

			this.setState({ error, balance: userBalance.data.balance });
		} catch (ex) {
			const error = {
				error: true,
				message: ex.response.data.message,
			};
			this.setState({ error });
			this.handleCloseModal("transferringFunds");
			this.handleOpenModal("transferSuccess");
		}
	}

	// Transfer amount input in add modal to Lenme Balance
	async transferToLenme() {
		try {
			const transfer = await fundingService.transferBalanceToLenme(this.state.data.add_amount);
			this.setState({ transferMessage: transfer.data.message });
			this.handleCloseModal("addingFunds");
			this.handleOpenModal("transferSuccess");

			// Refresh User balance
			const userBalance = await userService.getUserBalance();
			const error = {
				error: false,
			};

			this.setState({ error, balance: userBalance.data.balance });
		} catch (ex) {
			const error = {
				error: true,
				message: ex.response.data.message,
			};
			this.setState({ error });
			this.handleCloseModal("transferringFunds");
			this.handleOpenModal("transferSuccess");
		}
	}

	hideProviders = () => {
		this.setState({ showProviders: false });
	};

	render() {
		const {
			loading,
			showProviders,
			modal,
			balance,
			fundingResources,
			primaryFundingResource,
			data,
			transferMessage,
			hasBanks,
			error,
		} = this.state;
		const { userData } = this.props;
		const { user } = userData;

		// Check if loading
		if (userData.loading || loading) {
			return (
				<div className="bank-transfer-wrapper">
					<div className="bank-transfer-content">
						<img src={BankTransferLoadingBlock} alt="Loading..." style={{ width: "100%", maxWidth: 500 }} />
					</div>
				</div>
			);
		}

		// Render
		return (
			<div className="bank-transfer-wrapper">
				{/* Mobile Nav */}
				<MobileNav title="Bank Transfer" />

				<div className="bank-transfer-content">
					{/* Zero state verified */}
					{hasBanks && !showProviders && (
						<>
							<div className="bank-transfer-title d-none d-sm-block">Bank Transfer</div>

							<div>
								<div className="bank-transfer-subtitle pt-3">Lenme balance</div>
								<div className="d-flex align-items-baseline bank-transfer-balance-wrapper">
									$<div className="bank-transfer-balance">{handleBalanceFormat(balance)}</div>
								</div>
							</div>

							<hr className="w-100" />

							<div>
								<div className="bank-transfer-subtitle pt-3">Linked accounts</div>
								<div className="row">
									<div className="col-12">
										<div className="row">
											{fundingResources.map((resource) => {
												return (
													<div className="col-12 col-sm-6 col-lg-4 mt-4" key={resource.id}>
														<div className="funding-resource-card p-3">
															{resource.account_type && (
																<div className="funding-resource-card-title">
																	{resource.account_type.charAt(0).toUpperCase() +
																		resource.account_type.slice(1)}{" "}
																	- {resource.id}
																</div>
															)}
															<div className="funding-resource-card-bank-name">
																{resource.bank_name}
															</div>
															<div className="funding-resource-card-status">
																{resource.status.charAt(0).toUpperCase() +
																	resource.status.slice(1)}
															</div>
															{resource.is_primary && (
																<div className="primary-bank-text">Primary</div>
															)}
														</div>
													</div>
												);
											})}

											{!user.has_primary_funding_resource && (
												<div className="col-12 col-sm-6 col-lg-4 mt-4">
													<div
														className="funding-resource-card p-3 pointer flex-grow-1"
														onClick={() => {
															this.setState({ showProviders: true });
														}}>
														<div className="funding-resource-card-title">
															Add bank account
														</div>
														<img
															src={AddIcon}
															alt="Add new funding source"
															className="mt-3"
														/>
													</div>
												</div>
											)}
										</div>
									</div>

									{user.has_primary_funding_resource && (
										<div className="col-12 mt-5">
											<div className="row">
												<div className="col-12 col-sm-6">
													<div
														className="btn btn-outline-secondary btn-block btn-lg"
														onClick={() => {
															this.handleOpenModal("transferringFunds");
														}}>
														Transfer to bank
													</div>
												</div>
												<div className="col-12 col-sm-6 mt-3 mt-sm-0">
													<div
														className="btn btn-black btn-block btn-lg"
														onClick={() => {
															this.handleOpenModal("addingFunds");
														}}>
														Add money
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</>
					)}

					{/* Zero state unverified */}
					{!hasBanks && !showProviders && (
						<div className="zero-state-section">
							<div className="zero-state-title">Link Bank Account.</div>

							<div className="d-flex justify-content-center justify-content-md-start">
								<p>
									<div>
										In order for funds to be transferred automatically in and out of your account,
										you will need to link a bank account.
									</div>
									<div>
										Your data is protected. We don't store your bank credentials and use 256-bit
										encryption.
									</div>
								</p>
							</div>

							<div className="image-container">
								<img src={ZeroStateImage} alt="Not Found" />
							</div>

							<div className="d-flex justify-content-center justify-content-md-start">
								<button
									className="btn btn-lg btn-black btn-block my-3"
									onClick={() => {
										this.setState({ showProviders: true });
									}}>
									Get started
								</button>
							</div>
						</div>
					)}

					{/* Providers */}
					{showProviders && <BankProviders hideProviders={this.hideProviders} />}

					{/* Modals */}
					<Modal
						dialogClassName="bank-transfer-modal"
						show={modal.fastlinkSuccess.visible}
						onHide={() => this.handleCloseModal("fastlinkSuccess")}>
						<Modal.Body>
							<div className="d-flex align-items-center flex-wrap position-relative p-3">
								<div
									className="modal-close pointer"
									onClick={() => {
										this.handleCloseModal("fastlinkSuccess");
									}}>
									Close
								</div>
								<div className="bank-transfer-link-success-title p-3 flex-grow-1 w-100">Great!</div>
								<div className="p-3">You have successfully linked your bank account!</div>
								<div className="d-flex w-100 justify-content-center p-3 flex-wrap">
									<ResponseIllustration type="success" />
								</div>

								<div className="buttons-wrapper">
									<div
										className="btn btn-black btn-lg bank-link-success-button"
										onClick={() => {
											this.handleCloseModal("fastlinkSuccess");
										}}>
										Get Started
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>

					<Modal
						dialogClassName="bank-transfer-modal"
						show={modal.transferringFunds.visible}
						onHide={() => this.handleCloseModal("transferringFunds")}>
						<Modal.Body>
							<div className="d-flex align-items-center flex-wrap position-relative p-3">
								<div
									className="modal-close pointer"
									onClick={() => {
										this.handleCloseModal("transferringFunds");
									}}>
									Close
								</div>
								<div className="bank-transfer-link-success-title p-3 flex-grow-1 w-100">
									Transfer to Bank
								</div>
								<div className="p-3">Your current Lenme balance is ${handleBalanceFormat(balance)}</div>
								<div className="bank-transfer-popup d-flex w-100 p-3 m-3 flex-wrap">
									<div className="bank-transfer-popup-input">
										<LNInput
											placeholder={`$${handleBalanceFormat(balance)}`}
											name="transfer_amount"
											value={data.transfer_amount || ""}
											onChange={this.handleChange}
											removablePlaceholder={true}
											autoComplete="off"
										/>
									</div>
									<hr className="w-100 bank-transfer-popup-seperator" />
									{primaryFundingResource && (
										<div className="bank-transfer-popup-name mt-2">
											{primaryFundingResource.bank_name} - {primaryFundingResource.id}
										</div>
									)}
								</div>
								<div className="px-3 transfer-disclaimer">
									* The maximum amount per transaction is $10,000.
								</div>

								<div className="buttons-wrapper">
									<div
										className="btn btn-white btn-lg bank-transfer-button bank-transfer-cancel-button"
										onClick={() => {
											this.handleCloseModal("transferringFunds");
										}}>
										Cancel
									</div>
									<div
										className="btn btn-black btn-lg bank-link-success-button"
										onClick={() => {
											this.transferToBank();
										}}>
										{data.transfer_amount
											? `Transfer $${handleBalanceFormat(data.transfer_amount)}`
											: "Transfer to bank"}
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>

					<Modal
						dialogClassName="bank-transfer-modal"
						show={modal.transferSuccess.visible}
						onHide={() => this.handleCloseModal("transferSuccess")}>
						<Modal.Body>
							<div className="d-flex align-items-center flex-wrap position-relative p-3">
								<div
									className="modal-close pointer"
									onClick={() => {
										this.handleCloseModal("transferSuccess");
									}}>
									Close
								</div>
								<div className="bank-transfer-link-success-title p-3 flex-grow-1 w-100">
									{error.error ? "Oops!" : "Success!"}
								</div>
								<div className="p-3">{error.error ? error.message : transferMessage}</div>

								<div className="d-flex w-100 justify-content-center p-3 flex-wrap">
									{error.error ? (
										<ResponseIllustration type="error" />
									) : (
										<ResponseIllustration type="success" />
									)}
								</div>

								<div className="buttons-wrapper">
									<div
										className="btn btn-black btn-lg bank-link-success-button"
										onClick={() => {
											this.handleCloseModal("transferSuccess");
										}}>
										Done
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>

					<Modal
						dialogClassName="bank-transfer-modal"
						show={modal.addingFunds.visible}
						onHide={() => this.handleCloseModal("addingFunds")}>
						<Modal.Body>
							<div className="d-flex align-items-center flex-wrap position-relative p-3">
								<div
									className="modal-close pointer"
									onClick={() => {
										this.handleCloseModal("addingFunds");
									}}>
									Close
								</div>
								<div className="bank-transfer-link-success-title p-3 flex-grow-1 w-100">Add Money</div>
								<div className="p-3">Your current Lenme balance is ${handleBalanceFormat(balance)}</div>
								<div className="bank-transfer-popup d-flex w-100 p-3 m-3 flex-wrap">
									<div className="bank-transfer-popup-input">
										<LNInput
											placeholder="$0"
											name="add_amount"
											value={data.add_amount || ""}
											onChange={this.handleChange}
											removablePlaceholder={true}
											autoComplete="off"
										/>
									</div>
									<hr className="w-100 bank-transfer-popup-seperator" />
									{primaryFundingResource && (
										<div className="bank-transfer-popup-name mt-2">
											{primaryFundingResource.bank_name} - {primaryFundingResource.id}
										</div>
									)}
								</div>
								<div className="px-3 transfer-disclaimer">
									* The maximum amount per transaction is $10,000.
								</div>

								<div className="buttons-wrapper">
									<div
										className="btn btn-white btn-lg bank-transfer-button bank-transfer-cancel-button"
										onClick={() => {
											this.handleCloseModal("addingFunds");
										}}>
										Cancel
									</div>
									<div
										className="btn btn-black btn-lg bank-link-success-button"
										onClick={() => {
											this.transferToLenme();
										}}>
										{data.add_amount
											? `Add $${handleBalanceFormat(data.add_amount)}`
											: "Add to balance"}
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		userData: state.user.userData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchUserData: () => dispatch(fetchUserData()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BankTransferPage);
