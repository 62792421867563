import React, { Component } from "react";
import LNModal from "../common/LNModal/LNModal";
import ResponseIllustration from "../common/responseIllustration/responseIllustration";

class modalMessageFail extends Component {
	state = {
		modal: true,
	};

	// Render
	render() {
		// Render
		return (
			<LNModal
				show={this.state.modal}
				onHide={() =>
					this.setState({
						modal: false,
					})
				}
				dialogClassName="request-card-dialog">
				<div className="d-flex flex-column">
					<div style={{ justifyContent: "center" }}>
						<div className="font-size-14 mt-3" style={{ textAlign: "center" }}>
							Can't connect your account, Please try again.
						</div>
					</div>
					<ResponseIllustration type="error" />
				</div>
			</LNModal>
		);
	}
	handleCloseModal = (name) => {
		const modal = { ...this.state.modal };
		modal[name].visible = false;
		this.setState({ modal });
	};
}

export default modalMessageFail;
