import http from "./httpService";
import store from "../redux/store";


const apiVersion = store.getState().general.apiVersion;
const apiEndpoint = "/v" + apiVersion + "/notifications/";

// Get Notifications
export async function getNotifications() {
	return await http.get(apiEndpoint + "?user_as=lender");
}

// Get More Notifications
export async function getMoreNotifications(apiEndpointForMoreNotifications) {
	return await http.get(apiEndpointForMoreNotifications);
}

export default {
	getNotifications,
	getMoreNotifications,
};
