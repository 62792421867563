import React from "react";
import PropTypes from "prop-types";

// Import Bootstrap Components
import { Modal } from "react-bootstrap";

// CSS
import "./LNModal.css";

function LNModal({ children, dialogClassName = "", show, onHide, modalHeaderText, backdrop = true }) {
	return (
		<Modal dialogClassName={"general-modal " + dialogClassName} show={show} onHide={onHide} backdrop={backdrop}>
			<Modal.Header>
				<div>{modalHeaderText}</div>
				<button onClick={onHide} className="btn">
					Close
				</button>
			</Modal.Header>

			<Modal.Body>{children}</Modal.Body>
		</Modal>
	);
}

LNModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	modalHeaderText: PropTypes.string,
};

export default LNModal;
