import React, { useState } from "react";
import PropTypes from "prop-types";

// Images
import ShowPasswordIcon from "../../../img/icons/black/show_password.svg";
import HidePasswordIcon from "../../../img/icons/black/hide_password.svg";

// CSS
import "./LNFloatingInput.css";

const LNFloatingInput = ({
	size,
	type = "text",
	optional,
	name,
	value,
	onChange,
	onBlur,
	onClick,
	placeholder,
	autoComplete,
	text,
	error,
}) => {
	const [showPassword, setShowPassword] = useState(false);

	// Helper Method
	function handleClasses() {
		let classes = "form-control floating-input";

		// Size
		if (size === "lg") classes += " form-control-lg";
		if (size === "sm") classes += " form-control-sm";

		// Optional
		if (optional) classes += " form-control-optional";

		// Password
		if (type === "password") classes += " floating-password-input";

		// Error
		if (error) classes += " is-invalid";

		return classes;
	}

	// Render
	return (
		<div className="form-group floating-label">
			<input
				type={type === "password" && showPassword ? "text" : type}
				className={handleClasses()}
				name={name}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				onClick={onClick}
				placeholder=" "
				autoComplete={autoComplete}
			/>
			<label>{placeholder}</label>
			{optional && <span className="optional">Optional</span>}
			{!error && text && <small className="form-text text-muted">{text}</small>}
			{error && <div className="invalid-feedback">{error}</div>}

			{/* Show and hide password */}
			{type === "password" && (
				<button type="button" onClick={() => setShowPassword(!showPassword)} className="show-password-button">
					<img src={showPassword ? HidePasswordIcon : ShowPasswordIcon} alt="..." />
				</button>
			)}
		</div>
	);
};

// Prop Types
LNFloatingInput.propTypes = {
	size: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string,
	optional: PropTypes.bool,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onClick: PropTypes.func,
	placeholder: PropTypes.string,
	autoComplete: PropTypes.string,
	text: PropTypes.string,
	error: PropTypes.string,
};

export default LNFloatingInput;
