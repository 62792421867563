import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import history from "./history";
import logger from "./services/logService";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";

// CSS
import "swiper/css/swiper.css";
import "./styles/bootstrap/bootstrap.css";
import "./styles/bootstrap-overrides.css";
import "./styles/masterstyle.css";
import "./styles/style.css";

// App
import App from "./App";

// Service Worker
import * as serviceWorker from "./serviceWorker";

// Logger
logger.init();

// Render
ReactDOM.render(
	<Router history={history}>
		<Provider store={store}>
			<App />
		</Provider>
	</Router>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
