import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUserCompany } from "../../redux";
import { Formik } from "formik";
import * as Yup from "yup";

// Import Services

// Import Utils
import { getRegionList } from "../../utils/handleRegions";

// Import Components
import LNFloatingInput from "../common/LNFloatingInput/LNFloatingInput";
import LNInputMask from "../common/LNInputMask/LNInputMask";
import LNSelect from "../common/LNSelect/LNSelect";

// Import Images
import ArrowRight from "../../img/icons/black/arrow-right.svg";

// Import CSS
import "./editCompanyInfo.css";

class EditCompanyInfo extends Component {
	state = {};

	// When Mount
	componentDidMount() {}

	// Helper Methods
	handleMethod = () => {};

	render() {
		// Validations
		const ValidationSchema = Yup.object().shape({
			type: Yup.string()
				.max(255, "Too Long!")
				.required("Required field"),
			dba: Yup.string().max(255, "Too Long!"),
			ssn: Yup.string().min(9, "SSN Should be 9 digits."),
			street_address_1: Yup.string()
				.max(255, "Too Long!")
				.required("Required field"),
			street_address_2: Yup.string().max(255, "Too Long!"),
			city: Yup.string()
				.max(255, "Too Long!")
				.required("Required field"),
			state: Yup.string().required("Required field"),
			zip_code: Yup.string()
				.trim()
				.matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Please enter a valid U.S. zipcode.")
				.required("Required field"),
			// phone: Yup.string()
			// 	.trim()
			// 	.matches(/^\+1[^1][0-9]{9}$/, "Please enter a valid U.S. phone number.")
			// 	.required("Required field"),
			website: Yup.string()
				.url("Please enter a valid URL.")
				.nullable(),
		});

		const { companyData, updateUserCompany, reset_view_func } = this.props;
		const { company } = companyData;

		// Check if loading
		if (companyData.loading) return <div>Loading...</div>;

		// Render
		return (
			<div>
				<div className="d-flex flex-row align-items-center">
					<div
						className="profile-page-link"
						onClick={() => {
							reset_view_func();
						}}>
						Profile Page
					</div>
					<img src={ArrowRight} alt="Arrow Right" className="profile-page-arrow" />
					<div className="profile-page-link-name">Company Information</div>
				</div>
				<div className="row mt-5">
					<div className="col-6 offset-2">
						<div className="profile-section-title">Company Information</div>

						{/* Form */}
						<Formik
							initialValues={company}
							validationSchema={ValidationSchema}
							onSubmit={async (values, { setSubmitting, setErrors }) => {
								setSubmitting(true);
								const postData = {
									type: values.type,
									dba: values.dba,
									ssn: values.ssn.replace(/-/g, "").replace(/X/g, ""),
									street_address_1: values.street_address_1,
									street_address_2: values.street_address_2,
									city: values.city,
									state: values.state,
									zip_code: values.zip_code,
									phone:
										values.phone.charAt(0) === "+"
											? values.phone
											: "+1" + values.phone.replace(/-/g, "").replace(/X/g, ""),
									website: values.website,
								};
								Object.keys(postData).forEach(key => {
									if (!postData[key]) delete postData[key];
								});
								// Send Update request
								try {
									await updateUserCompany(postData);
									setSubmitting(false);
								} catch (error) {
									setErrors(error.response.data);
									setSubmitting(false);
								}
								// Send back to main profile page
								reset_view_func();
							}}>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								setFieldValue,
								setSubmitting,
								resetForm,
							}) => (
								<form onSubmit={handleSubmit} spellCheck={false}>
									<div className="inputs-wrapper">
										<LNSelect
											size="lg"
											name="type"
											value={values.type}
											defaultOption={{ value: "", name: "Company type", disabled: false }}
											options={[
												{
													name: "Sole Proprietorship",
													value: "sole",
												},
												{ name: "C-Corporation", value: "c-cop" },
												{ name: "S-Corporation", value: "s-cop" },
												{ name: "LLC", value: "llc" },
												{ name: "Partnership", value: "part" },
											]}
											onChange={e => {
												resetForm();
												setFieldValue("type", e.target.value);
											}}
											onBlur={handleBlur}
											error={touched.type && errors.type ? `* ${errors.type}` : ""}
										/>

										<LNFloatingInput
											size="lg"
											type="text"
											optional={true}
											name="dba"
											value={values.dba}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="Doing business as"
											error={touched.dba && errors.dba ? `* ${errors.dba}` : ""}
										/>

										<div>
											<LNInputMask
												mask="999-99-9999"
												maskChar="X"
												value={values.ssn}
												onChange={e => {
													const value =
														e.target.value.replace(/-/g, "").replace(/X/g, "") || "";
													setFieldValue("ssn", value);
												}}
												onBlur={handleBlur}
												size="lg"
												type="text"
												optional={false}
												name="ssn"
												placeholder="Social security number"
												text="Example: XXX-XX-1234"
												error={touched.ssn && errors.ssn ? `* ${errors.ssn}` : ""}
											/>
										</div>
									</div>

									<LNFloatingInput
										size="lg"
										type="text"
										name="street_address_1"
										value={values.street_address_1}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="Company Address 1"
										error={
											touched.street_address_1 && errors.street_address_1
												? `* ${errors.street_address_1}`
												: ""
										}
									/>

									<LNFloatingInput
										size="lg"
										type="text"
										optional={true}
										name="street_address_2"
										value={values.street_address_2}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="Company Address 2"
										error={
											touched.street_address_2 && errors.street_address_2
												? `* ${errors.street_address_2}`
												: ""
										}
									/>

									<LNFloatingInput
										size="lg"
										type="text"
										name="city"
										value={values.city}
										onChange={handleChange}
										onBlur={handleBlur}
										placeholder="City"
										error={touched.city && errors.city ? `* ${errors.city}` : ""}
									/>

									<LNSelect
										name="state"
										size="lg"
										value={values.state}
										onChange={handleChange}
										onBlur={handleBlur}
										defaultOption={{ name: "State", value: "" }}
										options={getRegionList("US")}
									/>

									<LNInputMask
										mask="99999"
										maskChar="X"
										value={values.zip_code}
										onChange={e => {
											const value = e.target.value.replace(/-/g, "").replace(/X/g, "");
											setFieldValue("zip_code", value);
										}}
										onBlur={handleBlur}
										size="lg"
										type="text"
										name="zip_code"
										placeholder="Zip Code"
										error={touched.zip_code && errors.zip_code ? `* ${errors.zip_code}` : ""}
									/>

									<LNInputMask
										mask="999-999-9999"
										maskChar="X"
										value={values.phone}
										onChange={e => {
											const value =
												"+1" + e.target.value.replace(/-/g, "").replace(/X/g, "") || "";
											setFieldValue("phone", value);
										}}
										onBlur={handleBlur}
										size="lg"
										type="text"
										name="phone"
										placeholder="Company Phone Number"
										error={touched.phone && errors.phone ? `* ${errors.phone}` : ""}
									/>

									<LNFloatingInput
										size="lg"
										type="text"
										name="website"
										value={values.website}
										optional={true}
										onChange={e => {
											const value =
												"https://" +
												e.target.value.replace("https://", "").replace("http://", "");
											setFieldValue("website", value);
										}}
										onBlur={handleBlur}
										placeholder="Company Website"
										error={touched.website && errors.website ? `* ${errors.website}` : ""}
									/>

									<div
										className="btn btn-lg btn-black w-100 mt-5"
										onClick={handleSubmit}
										disabled={isSubmitting}>
										Save
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		companyData: state.user.companyData,
	};
}
function mapDispatchToProps(dispatch) {
	return {
		updateUserCompany: updated_data => dispatch(updateUserCompany(updated_data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyInfo);
