import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Import Components
import Header from "../header/header";
import Sidebar from "../sideBar/sideBar";
import DashboardPage from "../dashboardPage/dashboardPage";
import FeedPage from "../feedPage/feedPage";
import InvestmentPage from "../investmentPage/investmentPage";
import BankTransferPage from "../bankTransferPage/bankTransferPage";
import SettingsPage from "../settingsPage/settingsPage";
import SupportPage from "../supportPage/supportPage";
import UserProfile from "../userProfile/userProfile";
import NotificationPage from "../notificationPage/notificationPage";

// Import CSS
import "./userHome.css";

class UserHome extends Component {
	state = {
		loading: true,
	};

	// Handle Loading
	handleLoading() {
		const loading = false;
		this.setState({ loading });
	}

	// When Mount
	componentDidMount() {
		// End loading
		this.handleLoading();
	}

	// Helper Methods
	handleOpenSideBar = () => {
		document.getElementById("sidebar").classList.remove("d-none");
	};

	handleCloseSideBar = () => {
		document.getElementById("sidebar").classList.add("d-none");
	};

	render() {
		const { loading } = this.state;

		// Check if loading
		if (loading) return <div>Loading...</div>;

		// Render
		return (
			<>
				{/* Header */}
				<Header handleOpen={this.handleOpenSideBar} />

				{/* Content */}
				<div className="user-home-page-container">
					{/* Side Bar */}
					<Sidebar handleClose={this.handleCloseSideBar} />

					{/* Body */}
					<div className="body">
						<Switch>
							<Route path="/user/dashboard/:loansType?" component={DashboardPage} />
							<Route path="/user/feed" component={FeedPage} />
							<Route path="/user/investment/:loansType?" component={InvestmentPage} />
							<Route path="/user/bank-transfer" component={BankTransferPage} />
							<Route path="/user/settings" component={SettingsPage} />
							<Route path="/user/support" component={SupportPage} />
							<Route path="/user/profile" component={UserProfile} />
							<Route path="/user/notification" component={NotificationPage} />
							<Redirect from="/user" to="/user/dashboard" />
						</Switch>
					</div>
				</div>
			</>
		);
	}
}

export default UserHome;
