import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
// Import Services
import auth from "../../services/authService";
import { login, fetchMoneyMade } from "../../redux";
import store from "../../redux/store";

// Import Components
import LNFloatingInput from "../common/LNFloatingInput/LNFloatingInput";
import LNError from "../common/LNError/LNError";
import LNModal from "../common/LNModal/LNModal";
import ResponseIllustration from "../common/responseIllustration/responseIllustration";

// Import Images
import Logo from "../../img/assets/logo.svg";

// Import CSS
import "./loginPage.css";

class LoginPage extends Component {
	state = {
		loading: true,
		modal: true,
		data: {
			email: "",
			password: "",
		},
		error: false,
		errorMessage: "",
	};

	// Get Account
	populateAccount() {
		const account = auth.getCurrentAccount();
		this.setState({ account });
	}

	// Handle Loading
	handleLoading() {
		const loading = false;
		this.setState({ loading });
	}

	// When Mount
	componentDidMount() {
		// Get account
		this.populateAccount();

		// End loading
		this.handleLoading();
	}

	// Render
	render() {
		const { loading, account, data, error, errorMessage } = this.state;
		const modalStatus = localStorage.getItem("modalStatus");
		const moneyMade = store.getState().user.moneyMade;
		console.log(moneyMade);
		// Check if account
		if (account) return <Redirect to="/" />;

		// Validations
		const ValidationSchema = Yup.object().shape({
			email: Yup.string()
				.email("Please enter a valid email address.")
				.max(255, "Too Long!")
				.required("Required field"),
			password: Yup.string().min(1, "Too Short!").max(255, "Too Long!").required("Required field"),
		});

		// Check if loading
		if (loading) return <div>Loading...</div>;

		// Render
		return (
			<div className="bg-landing">
				<div className="container">
					<div className="row no-gutters min-vh-100 justify-content-center align-items-center">
						<div className="col-12 col-md-10 col-lg-7 col-xl-6">
							<LNModal
								show={this.state.modal}
								onHide={() =>
									this.setState({
										modal: false,
									})
								}
								dialogClassName="request-card-dialog">
								{moneyMade.connected ? (
									moneyMade.moneyMadeStatus === "success" ? (
										<div>
											<div className="font-weight-bold">Success!</div>

											<div className="d-flex flex-column">
												<div style={{ justifyContent: "center" }}>
													<div className="font-size-14 mt-3" style={{ textAlign: "center" }}>
														Your account connected successfully.
													</div>
												</div>
												<div className="text-center my-4">
													<ResponseIllustration type="success" />
												</div>
											</div>
											<div className="modal-buttons-wrapper horizontal">
												<button
													onClick={this.handleMakeOfferCompleteConfirm}
													className="btn btn-black btn-lg">
													Done
												</button>
											</div>
										</div>
									) : (
										<div>
											<div className="font-weight-bold">Something went wrong!</div>
											<div className="d-flex flex-column">
												<div style={{ justifyContent: "center" }}>
													<div className="font-size-14 mt-3" style={{ textAlign: "center" }}>
														Can't connect your account, Please try again.
													</div>
												</div>
												<div className="text-center my-4">
													<ResponseIllustration type="error" />
												</div>
											</div>
											<div className="modal-buttons-wrapper horizontal">
												<button
													onClick={() => {
														this.setState({
															modal: false,
														});
													}}
													className="btn btn-black btn-lg">
													Close
												</button>
											</div>
										</div>
									)
								) : (
									<div className="pre-login-form shadow my-3">
										<div className="mb-3 logo">
											<img src={Logo} alt="Logo" />
										</div>
										<div className=" mb-1 title">Connect Your Lenme Account</div>
										<div className="font-size-14  mb-3">
											By connecting your account, you are authorizing{" "}
											<a href={"https://moneymade.io/"}>MoneyMade.io </a>to access your account
											transaction history.
										</div>
										{error && <LNError text={errorMessage} />}

										<div className="mb-2 font-weight-bold">Login</div>

										<div className="mb-2">
											<Formik
												initialValues={data}
												validationSchema={ValidationSchema}
												onSubmit={async (values, { setSubmitting, setErrors }) => {
													console.log("modalStatus", modalStatus);

													try {
														setSubmitting(true);

														// await auth.login(values);
														this.props.login(values);

														// this.setState({
														// 	modal: true,
														// });
														// if (modalStatus) {
														// 	window.opener.postMessage({ status: "OK" }, "*");
														// 	this.setState({
														// 		modal: true,
														// 	});
														// } else {
														// 	window.opener.postMessage({ status: "FAILED" }, "*");
														// 	this.setState({
														// 		modal: true,
														// 	});
														// }
														setSubmitting(false);
													} catch (error) {
														console.warn(error);

														setSubmitting(false);
													}
												}}>
												{({
													values,
													errors,
													touched,
													handleChange,
													handleBlur,
													handleSubmit,
													isSubmitting,
												}) => (
													<form onSubmit={handleSubmit}>
														<LNFloatingInput
															size="lg"
															type="email"
															optional={false}
															name="email"
															value={values.email}
															onChange={handleChange}
															onBlur={handleBlur}
															placeholder="Lenme Email"
															error={
																touched.email && errors.email ? `* ${errors.email}` : ""
															}
														/>

														<LNFloatingInput
															size="lg"
															type="password"
															optional={false}
															name="password"
															value={values.password}
															onChange={handleChange}
															onBlur={handleBlur}
															placeholder="Lenme Password"
															error={
																touched.password && errors.password
																	? `* ${errors.password}`
																	: ""
															}
														/>

														<button
															className="btn btn-primary btn-lg btn-block mt-4"
															type="submit"
															disabled={isSubmitting}
															onClick={handleSubmit}>
															Log in
														</button>
													</form>
												)}
											</Formik>
										</div>
									</div>
								)}
							</LNModal>
						</div>
					</div>
				</div>
			</div>
		);
	}
	handleCloseModal = (name) => {
		const modal = { ...this.state.modal };
		modal[name].visible = false;
		this.setState({ modal });
	};
}
const mapStateToProps = (state) => {
	return {
		moneyMade: state.user.moneyMade,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchMoneyMade: (token, signature, payload) => {
			dispatch(fetchMoneyMade(token, signature, payload));
		},
		login: (value) => {
			dispatch(login(value));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
