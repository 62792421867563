import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

// Import Services

// Import Utils

// Import Material Components

// Import Components

// Import Images

// Import CSS
import "./LNInputMask.css";

const LNInputMask = ({
	mask,
	maskChar,
	value,
	onChange,
	onBlur,
	size,
	type,
	optional,
	name,
	placeholder,
	text,
	error,
}) => {
	// Helper Method
	function handleClasses() {
		let classes = "form-control floating-input";

		// Size
		if (size === "lg") classes += " form-control-lg";
		if (size === "sm") classes += " form-control-sm";

		// Optional
		if (optional) classes += " form-control-optional";

		// Error
		if (error) classes += " is-invalid";

		return classes;
	}

	// Render
	return (
		<InputMask mask={mask} maskChar={maskChar} value={value} onChange={onChange} onBlur={onBlur}>
			{() => (
				<div className="form-group floating-label">
					<input type={type} className={handleClasses()} name={name} placeholder=" " />
					<label>{placeholder}</label>
					{optional && <span className="optional">Optional</span>}
					{!error && text && <small className="form-text text-muted">{text}</small>}
					{error && <div className="invalid-feedback">{error}</div>}
				</div>
			)}
		</InputMask>
	);
};

// Prop Types
LNInputMask.propTypes = {
	mask: PropTypes.string,
	maskChar: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	size: PropTypes.string,
	type: PropTypes.string,
	optional: PropTypes.bool,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	text: PropTypes.string,
	error: PropTypes.string,
};

export default LNInputMask;
