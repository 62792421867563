import axios from "axios";
import logger from "./logService";
import refreshTokenInterceptor from "./refreshTokenService";
import auth from "./authService";

// Keys
const accessTokenKey = "accessToken";

let baseURL 
if(window.location.href.includes('staging') || window.location.href.includes('local') ){
	baseURL = 'https://staging.lenmo.app/api'
}else {
	baseURL = 'https://api.lenmo.app/api'
}

axios.defaults.baseURL = baseURL;

// Interceptors Configuration
axios.interceptors.request.use(async request => {
	const isLoggedIn = auth.getCurrentAccount();
	if (!isLoggedIn) return request;

	await refreshTokenInterceptor();
	const accessToken = localStorage.getItem(accessTokenKey);
	request.headers["Authorization"] = "Bearer " + accessToken;
	return request;
});

axios.interceptors.response.use(null, error => {
	if (error.response.status === 401) window.location.reload();

	const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

	if (!expectedError) {
		logger.log(error);
		alert("An unexpected error occurrred.");
	}

	return Promise.reject(error);
});

export default {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	delete: axios.delete,
};
