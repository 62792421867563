import http from "./httpService";
import store from "../redux/store";


const apiVersion = store.getState().general.apiVersion;
const apiEndpoint = "/v" + apiVersion + "/feed/?type=investing";

// Get Feed Data
export async function getFeed() {
	return await http.get(apiEndpoint);
}

// Get Feed Next
export async function getFeedNext(next) {
	return await http.get(next);
}
