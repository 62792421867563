import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchLoanRequests, fetchMoreLoanRequests } from "../../redux";

// Components
import RequestCard from "../requestCard/requestCard";
import ShowMore from "../common/showMore/showMore";

// Images
import RequestsEmpty from "../../img/static/requests_empty.svg";
import LoanCardsLoadingBlocks from "../../img/spinners/blocks/loan cards.svg";

// CSS
import "./requests.css";

function Requests() {
	const loanRequests = useSelector((state) => state.dashboard.loanRequests);
	const { results: requests = [] } = loanRequests.data;
	const dispatch = useDispatch();

	// When Mount
	useEffect(() => {
		dispatch(fetchLoanRequests());

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Check if loading
	if (loanRequests.loading) {
		return (
			<div>
				<img src={LoanCardsLoadingBlocks} alt="Loading..." className="w-100" />
			</div>
		);
	}

	// Zero State
	if (requests.length === 0) {
		return (
			<div className="requests-zero-state mt-3">
				<div> We were unable to find any results. </div>
				<div> Please adjust your search.</div>
				<img className="pt-5" src={RequestsEmpty} alt="No Requests Found" />
			</div>
		);
	}

	// Render
	return (
		<div>
			{requests.map((item) => (
				<RequestCard key={item.id} data={item} />
			))}

			{loanRequests.data.next && (
				<ShowMore
					loading={loanRequests.paginate.loading}
					handleClick={() => dispatch(fetchMoreLoanRequests())}
				/>
			)}
		</div>
	);
}

export default Requests;
