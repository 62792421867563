import http from "./httpService";
import store from "../redux/store";


const apiVersion = store.getState().general.apiVersion;
const apiEndpoint = "/enterprise/v" + apiVersion + "/company_owners/";

// Get Owners
export async function getOwners() {
	return await http.get(apiEndpoint);
}

// Add Owner
export async function addOwner(postData) {
	return await http.post(apiEndpoint, postData);
}

// Edit Owner
export async function editOwner(user_id, postData) {
	return await http.put(apiEndpoint + user_id, postData);
}

// Delete Owner
export async function deleteOwner(id) {
	return await http.delete(apiEndpoint + id);
}

// Certify Owner
export async function certifyOwner(data) {
	return await http.post("enterprise/v" + apiVersion + "/profile/certify_beneficial_ownership/", data);
}

export default {
	getOwners,
	editOwner,
	deleteOwner,
	addOwner,
	certifyOwner,
};
