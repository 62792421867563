import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/analytics";
import "firebase/performance";
import "firebase/storage";
import "firebase/database";
import "firebase/auth";

// Stagin Config
const StagingConfig = {
	apiKey: "AIzaSyCII_kqlxl_PAcbfAbBLFsWBP9dfvLLe3M",
	authDomain: "lenmo-2a623.firebaseapp.com",
	databaseURL: "https://lenmo-2a623.firebaseio.com",
	projectId: "lenmo-2a623",
	storageBucket: "lenmo-2a623.appspot.com",
	messagingSenderId: "478466344575",
	appId: "1:478466344575:web:b82ce9761adb7c730fe14a",
	measurementId: "G-E8YN7YEKT7",
};

// Production Config
const productionConfig = {
	apiKey: "AIzaSyCII_kqlxl_PAcbfAbBLFsWBP9dfvLLe3M",
	authDomain: "lenmo-2a623.firebaseapp.com",
	databaseURL: "https://lenmo-2a623.firebaseio.com",
	projectId: "lenmo-2a623",
	storageBucket: "lenmo-2a623.appspot.com",
	messagingSenderId: "478466344575",
	appId: "1:478466344575:web:e990d308dc29008a0fe14a",
	measurementId: "G-3MZPZ0B0C7",
};

// Init
const { REACT_APP_ENVIRONMENT } = process.env;
firebase.initializeApp(REACT_APP_ENVIRONMENT === "development" ? StagingConfig : productionConfig);

export default {
	messaging: firebase.messaging,
	analytics: firebase.analytics,
	performance: firebase.performance,
	storage: firebase.storage,
	database: firebase.database,
	auth: firebase.auth,
	googleAuthProvider: new firebase.auth.GoogleAuthProvider(),
};
