import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";

// Import Services
import http from "../../../services/httpService";

// Import Utils
import { handleReactGAEvents } from "../../../utils/handleReactGAEvents";

// Import Components
import LNFloatingInput from "../../common/LNFloatingInput/LNFloatingInput";
import LNError from "../../common/LNError/LNError";
import store from "../../../redux/store";

// Import CSS
import "./personalInfo.css";

class PersonalInfo extends Component {
	state = {
		data: {
			first_name: "",
			middle_name: "",
			last_name: "",
		},
		error: false,
		errorMessages: [],
	};

	// Helper Methods
	handleSaveAndExit = async (handleSubmit, values) => {
		await handleSubmit();

		const { first_name, last_name } = values;
		if (first_name === "" || last_name === "") return;
		window.location = "/";
	};

	handleSubmit = async (values, { setSubmitting, setErrors }) => {
		try {
			setSubmitting(true);

			const postData = {
				first_name: values.first_name,
				middle_name: values.middle_name,
				last_name: values.last_name,
			};

			Object.keys(postData).forEach((key) => {
				if (!postData[key]) delete postData[key];
			});

			const apiVersion = store.getState().general.apiVersion;

			await http.post(`enterprise/v${apiVersion}/register/personal_info/`, postData);

			this.setState({ error: false });
			setSubmitting(false);

			this.props.incrementfunction();

			// Send GA event
			handleReactGAEvents("Sign_up_step_1");
		} catch (ex) {
			console.log("Error: ", ex);

			this.setState({
				error: true,
				errorMessages: ex.response.data,
			});

			setErrors(ex.response.data);
			setSubmitting(false);
		}
	};

	// Render
	render() {
		const { data, error, errorMessages } = this.state;

		// Validations
		const ValidationSchema = Yup.object().shape({
			first_name: Yup.string().max(255, "Too Long!").required("Required field"),
			middle_name: Yup.string().max(255, "Too Long!"),
			last_name: Yup.string().max(255, "Too Long!").required("Required field"),
		});

		// Render
		return (
			<div className="signup-process-form personal-info">
				{/* header */}
				<div className="form-header mb-5">
					<div className="main-form-label">Start investing with Lenme Enterprise</div>
					<div>Let's start with the basics.</div>
				</div>

				{/* Form */}
				<Formik initialValues={data} validationSchema={ValidationSchema} onSubmit={this.handleSubmit}>
					{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
						<form onSubmit={handleSubmit}>
							<div className="inputs-wrapper">
								{error && <LNError text={errorMessages} />}

								<LNFloatingInput
									size="lg"
									type="text"
									optional={false}
									name="first_name"
									value={values.first_name}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="First Name"
									error={touched.first_name && errors.first_name ? `* ${errors.first_name}` : ""}
								/>

								<LNFloatingInput
									size="lg"
									type="text"
									optional={true}
									name="middle_name"
									value={values.middle_name}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Middle Name"
									error={touched.middle_name && errors.middle_name ? `* ${errors.middle_name}` : ""}
								/>

								<LNFloatingInput
									size="lg"
									type="text"
									optional={false}
									name="last_name"
									value={values.last_name}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Last Name"
									error={touched.last_name && errors.last_name ? `* ${errors.last_name}` : ""}
								/>
							</div>

							<div className="d-flex mt-5 buttons-wrapper">
								<div className="mr-3">
									<button
										type="submit"
										className="btn btn-white btn-lg btn-block"
										onClick={() => this.handleSaveAndExit(handleSubmit, values)}
										disabled={isSubmitting}>
										Save and Exit
									</button>
								</div>

								<div className="flex-grow-1">
									<button
										type="submit"
										className="btn btn-primary btn-lg btn-block"
										onClick={handleSubmit}
										disabled={isSubmitting}>
										Next
									</button>
								</div>
							</div>
						</form>
					)}
				</Formik>
			</div>
		);
	}
}

// Prop Types
PersonalInfo.propTypes = {
	incrementfunction: PropTypes.func.isRequired,
};

export default PersonalInfo;
