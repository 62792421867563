import React from "react";
import { Route, Redirect } from "react-router-dom";

// Import Services
import auth from "../../../services/authService";

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
	return (
		<Route
			path={path}
			render={props => {
				if (!auth.getCurrentAccount())
					return (
						<Redirect
							to={{
								pathname: "/oauth",
								state: { from: props.location },
							}}
						/>
					);
				return Component ? <Component {...props} /> : render(props);
			}}
		/>
	);
};

export default ProtectedRoute;
