import userService from "../../services/userService";

import ownerService from "../../services/ownerService";
import authService, { loginWithJwt, logout } from "../../services/authService";
// Types
import {
	FETCH_USER_DATA_REQUEST,
	FETCH_USER_DATA_SUCCESS,
	FETCH_USER_DATA_FAILURE,
	FETCH_USER_COMPANY_REQUEST,
	FETCH_USER_COMPANY_SUCCESS,
	FETCH_USER_COMPANY_FAILURE,
	FETCH_USER_LICENSE_REQUEST,
	FETCH_USER_LICENSE_SUCCESS,
	FETCH_USER_LICENSE_FAILURE,
	FETCH_USER_OWNERS_REQUEST,
	FETCH_USER_OWNERS_SUCCESS,
	FETCH_USER_OWNERS_FAILURE,
	UPDATE_USER_PROFILE_REQUEST,
	UPDATE_USER_PROFILE_SUCCESS,
	UPDATE_USER_PROFILE_FAILURE,
	UPDATE_USER_COMPANY_REQUEST,
	UPDATE_USER_COMPANY_SUCCESS,
	UPDATE_USER_COMPANY_FAILURE,
	ADD_USER_OWNER_REQUEST,
	ADD_USER_OWNER_SUCCESS,
	ADD_USER_OWNER_FAILURE,
	FETCH_AGGREGATION_DATA_REQUEST,
	FETCH_AGGREGATION_DATA_SUCCESS,
	FETCH_AGGREGATION_DATA_FAILURE,
	FETCH_CURRENT_BALANCE_REQUEST,
	FETCH_CURRENT_BALANCE_SUCCESS,
	FETCH_CURRENT_BALANCE_FAILURE,
	FETCH_AVERAGE_RATE_OF_RETURN_REQUEST,
	FETCH_AVERAGE_RATE_OF_RETURN_SUCCESS,
	FETCH_AVERAGE_RATE_OF_RETURN_FAILURE,
	FETCH_CURRENT_LOAN_REQUEST,
	FETCH_CURRENT_LOAN_SUCCESS,
	FETCH_CURRENT_LOAN_FAILURE,
	FETCH_TOTAL_INVESTED_REQUEST,
	FETCH_TOTAL_INVESTED_SUCCESS,
	FETCH_TOTAL_INVESTED_FAILURE,
	FETCH_TOTAL_EARNED_REQUEST,
	FETCH_TOTAL_EARNED_SUCCESS,
	FETCH_TOTAL_EARNED_FAILURE,
	FETCH_MONEY_MADE_REQUEST,
	FETCH_MONEY_MADE_SUCCESS,
	FETCH_MONEY_MADE_FAILURE
} from "./types";



// Fetch MoneyMade Data

export const login =  (data) =>{
	return async dispatch =>{
		let response = await authService.login(data)
		if (response) {
			/* Handling response */
			switch (response.status) {
				case 200:
					/* Handling Success */
					console.log('login success')
					loginWithJwt(response.data.access,response.data.refresh)
					dispatch(fetchMoneyMade())
					break;
				case 201:
					/* Handling Success */
					console.log('login success')
					dispatch(fetchMoneyMade())
					break;
				case 400:
					/* Handling user not found */
					console.log("error")
					break;
				case 403:
					/* Handling forbidden request */
					console.log("error")
					break;
				default:
					/* Handling internal error */
					console.log("error")
					break;
			}
		}else{
			console.log('error')
		}
	
	}
		
}

export const fetchMoneyMadeRequest = () => {
	return {
		type: FETCH_MONEY_MADE_REQUEST,
	};
};

export const fetchMoneyMadeSuccess = () => {
	return {
		type: FETCH_MONEY_MADE_SUCCESS,
		
	};
};

export const fetchMoneyMadeFailure = () => {
	logout()
	return {
		type: FETCH_MONEY_MADE_FAILURE,
	};
};

// Fetch User Data
export const fetchUserDataRequest = () => {
	return {
		type: FETCH_USER_DATA_REQUEST,
	};
};

export const fetchUserDataSuccess = (user) => {
	return {
		type: FETCH_USER_DATA_SUCCESS,
		payload: user,
	};
};

export const fetchUserDataFailure = (error) => {
	return {
		type: FETCH_USER_DATA_FAILURE,
		payload: error,
	};
};

export const fetchUserData = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchUserDataRequest());
			const { data } = await userService.getUser();
			dispatch(fetchUserDataSuccess(data));
		} catch (ex) {
			dispatch(fetchUserDataFailure(ex));
			console.error("Error: ", ex);
		}
	};
};
 export const fetchMoneyMade = () => {
	// return async function (dispatch) {
	// 	dispatch(fetchMoneyMadeRequest());
	// 	const response = moneyMade()

	// 	// dispatch(fetchMoneyMadeSuccess());
	// 	// dispatch(fetchUserDataFailure());
	console.log('start money made')
	return async (dispatch)=>{	
		const token = localStorage.getItem("accessToken");
		dispatch(fetchMoneyMadeRequest())
		
		const query = new URLSearchParams(window.location.search);
		const payload = query.get("payload");
		const signature = query.get("signature");

		console.log('payload',payload)
		console.log('signature',signature)
		let response = await authService.moneyMade(token,signature,payload)
		if (response) {
			console.log('fetchMoneyMade')
			/* Handling response */
			switch (response.status) {
				case 200:
					/* Handling Success */
					console.log(' success')
					dispatch(fetchMoneyMadeSuccess())
					break;
				case 201:
					/* Handling Success */
					console.log(' success')
					dispatch(fetchMoneyMadeSuccess())

					break;
				case 400:
					/* Handling user not found */
				console.log("error")
				dispatch(fetchMoneyMadeFailure())

					break;
				case 403:
					/* Handling forbidden request */
					console.log("error")
					dispatch(fetchMoneyMadeFailure())

					break;
				default:
					/* Handling internal error */
					console.log("error")
					dispatch(fetchMoneyMadeFailure())

					break;
			}
		}else{
			console.log('error')
			dispatch(fetchMoneyMadeFailure())
		}
	}	
		
 };
// export const fetchMoneyMade = () => {
// 	return async function (dispatch) {
// 		try {
// 			dispatch(fetchMoneyMadeRequest());
// 		} catch (err) {
// 			dispatch(fetchMoneyMadeFailure());
		
// 		}
// 	};
// };
// export const fetchMoneyMadeSuc = () => {
// 	return async function (dispatch) {
// 		try {
// 			dispatch(fetchMoneyMadeSuccess());
// 		} catch (err) {
// 			dispatch(fetchMoneyMadeFailure());
		
// 		}
// 	};
// };
// export const fetchMoneyMadeFai = () => {
// 	return async function (dispatch) {
// 		try {
// 			dispatch(fetchMoneyMadeFailure());
// 		} catch (err) {
// 			dispatch(fetchMoneyMadeFailure());
		
// 		}
// 	};
// };

// Fetch Company Data
export const fetchCompanyDataRequest = () => {
	return {
		type: FETCH_USER_COMPANY_REQUEST,
	};
};

export const fetchCompanyDataSuccess = (data) => {
	return {
		type: FETCH_USER_COMPANY_SUCCESS,
		payload: data,
	};
};

export const fetchCompanyDataFailure = (error) => {
	return {
		type: FETCH_USER_COMPANY_FAILURE,
		payload: error,
	};
};

export const fetchCompanyData = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchCompanyDataRequest());

			const { data } = await userService.getCompany();
			dispatch(fetchCompanyDataSuccess(data));
		} catch (ex) {
			dispatch(fetchCompanyDataFailure(ex));
			console.error("Error: ", ex);
		}
	};
};

// Fetch License Data
export const fetchLicenseDataRequest = () => {
	return {
		type: FETCH_USER_LICENSE_REQUEST,
	};
};

export const fetchLicenseDataSuccess = (data) => {
	return {
		type: FETCH_USER_LICENSE_SUCCESS,
		payload: data,
	};
};

export const fetchLicenseDataFailure = (error) => {
	return {
		type: FETCH_USER_LICENSE_FAILURE,
		payload: error,
	};
};

export const fetchLicenseData = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchLicenseDataRequest());

			const { data } = await userService.getLicense();
			dispatch(fetchLicenseDataSuccess(data));
		} catch (ex) {
			dispatch(fetchLicenseDataFailure(ex));
			console.error("Error: ", ex);
		}
	};
};

// Fetch Owners Data
export const fetchOwnersDataRequest = () => {
	return {
		type: FETCH_USER_OWNERS_REQUEST,
	};
};

export const fetchOwnersDataSuccess = (data) => {
	return {
		type: FETCH_USER_OWNERS_SUCCESS,
		payload: data,
	};
};

export const fetchOwnersDataFailure = (error) => {
	return {
		type: FETCH_USER_OWNERS_FAILURE,
		payload: error,
	};
};

export const fetchOwnersData = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchOwnersDataRequest());

			const { data } = await ownerService.getOwners();
			dispatch(fetchOwnersDataSuccess(data));
		} catch (ex) {
			dispatch(fetchOwnersDataFailure(ex));
			console.error("Error: ", ex);
		}
	};
};

// Update User Profile
export const updateUserProfileRequest = () => {
	return {
		type: UPDATE_USER_PROFILE_REQUEST,
	};
};

export const updateUserProfileSuccess = (updated_data) => {
	return {
		type: UPDATE_USER_PROFILE_SUCCESS,
		payload: updated_data,
	};
};

export const updateUserProfileFailure = (error) => {
	return {
		type: UPDATE_USER_PROFILE_FAILURE,
		payload: error,
	};
};

export const updateUserProfile = (updated_data) => {
	return async function (dispatch) {
		try {
			dispatch(updateUserProfileRequest());
			const { data } = await userService.updateUserProfile(updated_data);
			dispatch(updateUserProfileSuccess(data));
		} catch (ex) {
			dispatch(updateUserProfileFailure(ex));
			console.error("Error: ", ex);
		}
	};
};

// Update Company Profile
export const updateUserCompanyRequest = () => {
	return {
		type: UPDATE_USER_COMPANY_REQUEST,
	};
};

export const updateUserCompanySuccess = (updated_data) => {
	return {
		type: UPDATE_USER_COMPANY_SUCCESS,
		payload: updated_data,
	};
};

export const updateUserCompanyFailure = (error) => {
	return {
		type: UPDATE_USER_COMPANY_FAILURE,
		payload: error,
	};
};

export const updateUserCompany = (updated_data) => {
	return async function (dispatch) {
		try {
			dispatch(updateUserCompanyRequest());
			const { data } = await userService.updateUserCompany(updated_data);
			dispatch(updateUserCompanySuccess(data));
		} catch (ex) {
			dispatch(updateUserCompanyFailure(ex));
			console.error("Error: ", ex);
		}
	};
};

// Add New Owner
export const addUserOwnerRequest = () => {
	return {
		type: ADD_USER_OWNER_REQUEST,
	};
};

export const addUserOwnerSuccess = (new_owner) => {
	return {
		type: ADD_USER_OWNER_SUCCESS,
		payload: new_owner,
	};
};

export const addUserOwnerFailure = (error) => {
	return {
		type: ADD_USER_OWNER_FAILURE,
		payload: error,
	};
};

export const addUserOwner = (new_owner) => {
	return async function (dispatch) {
		try {
			dispatch(addUserOwnerRequest());
			const { data } = await ownerService.addOwner(new_owner);
			dispatch(addUserOwnerSuccess(data));
		} catch (ex) {
			dispatch(addUserOwnerFailure(ex));
			console.error("Error: ", ex);
		}
	};
};

// Fetch Aggregation Data
export const fetchAggregationDataRequest = () => {
	return {
		type: FETCH_AGGREGATION_DATA_REQUEST,
	};
};

export const fetchAggregationDataSuccess = (data) => {
	return {
		type: FETCH_AGGREGATION_DATA_SUCCESS,
		payload: data,
	};
};

export const fetchAggregationDataFailure = (error) => {
	return {
		type: FETCH_AGGREGATION_DATA_FAILURE,
		payload: error,
	};
};

export const fetchAggregationData = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchAggregationDataRequest());

			const { data } = await userService.getAggregationData();
			dispatch(fetchAggregationDataSuccess(data));
		} catch (ex) {
			dispatch(fetchAggregationDataFailure(ex));
			console.error("Error: ", ex);
		}
	};
};

// Fetch Current Balance
export const fetchCurrentBalanceRequest = () => {
	return {
		type: FETCH_CURRENT_BALANCE_REQUEST,
	};
};

export const fetchCurrentBalanceSuccess = (data) => {
	return {
		type: FETCH_CURRENT_BALANCE_SUCCESS,
		payload: data,
	};
};

export const fetchCurrentBalanceFailure = (error) => {
	return {
		type: FETCH_CURRENT_BALANCE_FAILURE,
		payload: error,
	};
};

export const fetchCurrentBalance = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchCurrentBalanceRequest());

			const { data } = await userService.getCurrentBalance();
			dispatch(fetchCurrentBalanceSuccess(data));
		} catch (ex) {
			dispatch(fetchCurrentBalanceFailure(ex));
			console.error("Error: ", ex);
		}
	};
};

// Fetch Average Rate Of Return
export const fetchAverageRateOfReturnRequest = () => {
	return {
		type: FETCH_AVERAGE_RATE_OF_RETURN_REQUEST,
	};
};

export const fetchAverageRateOfReturnSuccess = (data) => {
	return {
		type: FETCH_AVERAGE_RATE_OF_RETURN_SUCCESS,
		payload: data,
	};
};

export const fetchAverageRateOfReturnFailure = (error) => {
	return {
		type: FETCH_AVERAGE_RATE_OF_RETURN_FAILURE,
		payload: error,
	};
};

export const fetchAverageRateOfReturn = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchAverageRateOfReturnRequest());

			const { data } = await userService.getAvgOfReturn();
			dispatch(fetchAverageRateOfReturnSuccess(data));
		} catch (ex) {
			dispatch(fetchAverageRateOfReturnFailure(ex));
			console.error("Error: ", ex);
		}
	};
};

// Fetch Current Loan
export const fetchCurrentLoanRequest = () => {
	return {
		type: FETCH_CURRENT_LOAN_REQUEST,
	};
};

export const fetchCurrentLoanSuccess = (data) => {
	return {
		type: FETCH_CURRENT_LOAN_SUCCESS,
		payload: data,
	};
};

export const fetchCurrentLoanFailure = (error) => {
	return {
		type: FETCH_CURRENT_LOAN_FAILURE,
		payload: error,
	};
};

export const fetchCurrentLoan = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchCurrentLoanRequest());

			const { data } = await userService.getCurrentLoan();
			dispatch(fetchCurrentLoanSuccess(data));
		} catch (ex) {
			dispatch(fetchCurrentLoanFailure(ex));
			console.error("Error: ", ex);
		}
	};
};

// Fetch Total Invested
export const fetchTotalInvestedRequest = () => {
	return {
		type: FETCH_TOTAL_INVESTED_REQUEST,
	};
};

export const fetchTotalInvestedSuccess = (data) => {
	return {
		type: FETCH_TOTAL_INVESTED_SUCCESS,
		payload: data,
	};
};

export const fetchTotalInvestedFailure = (error) => {
	return {
		type: FETCH_TOTAL_INVESTED_FAILURE,
		payload: error,
	};
};

export const fetchTotalInvested = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchTotalInvestedRequest());

			const { data } = await userService.getTotalInvested();
			dispatch(fetchTotalInvestedSuccess(data));
		} catch (ex) {
			dispatch(fetchTotalInvestedFailure(ex));
			console.error("Error: ", ex);
		}
	};
};

// Fetch Total Earned
export const fetchTotalEarnedRequest = () => {
	return {
		type: FETCH_TOTAL_EARNED_REQUEST,
	};
};

export const fetchTotalEarnedSuccess = (data) => {
	return {
		type: FETCH_TOTAL_EARNED_SUCCESS,
		payload: data,
	};
};

export const fetchTotalEarnedFailure = (error) => {
	return {
		type: FETCH_TOTAL_EARNED_FAILURE,
		payload: error,
	};
};

export const fetchTotalEarned = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchTotalEarnedRequest());

			const { data } = await userService.getTotalEarned();
			dispatch(fetchTotalEarnedSuccess(data));
		} catch (ex) {
			dispatch(fetchTotalEarnedFailure(ex));
			console.error("Error: ", ex);
		}
	};
};
