import firebase from "./firebaseInitExtended";

let messaging = null;

// Check if firebase messaging SDK is supported
if (firebase.messaging.isSupported()) {
	// Retrieve Firebase Messaging object.
	messaging = firebase.messaging();

	// Get registration token. Initially this makes a network call, once retrieved
	// subsequent calls to getToken will return from cache.
	messaging
		.getToken({ vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
		.then((currentToken) => {
			if (currentToken) {
				console.log(currentToken);
				// Do something...
			} else {
				// Show permission request.
				console.log("No registration token available. Request permission to generate one.");
			}
		})
		.catch((err) => {
			console.log("An error occurred while retrieving token.\n\n" + err);
		});
}

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker
export const onMessageListener = () =>
	new Promise((resolve, reject) => {
		if (messaging) {
			messaging.onMessage((payload) => {
				resolve(payload);
			});
		} else {
			reject("FCM isn't supported on this browser.");
		}
	});
