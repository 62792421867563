import axios from "axios";
import moment from "moment";

// Keys
const refreshTokenKey = "refreshToken";
const accessTokenKey = "accessToken";
let lastRequestDate = null;

async function getToken() {
	if (lastRequestDate !== null && !isTokenExpired()) {
		return localStorage.getItem(accessTokenKey);
	}

	// Set last call date
	lastRequestDate = new Date();

	// Getting refresh token
	return await refreshExpiredToken();
}

function isTokenExpired() {
	let end = moment.utc(new Date());
	let minutes = moment.duration(end.diff(lastRequestDate)).minutes();
	if (minutes < 5) return false;
	if (minutes >= 15) return logoutUser();
	return true;
}

async function refreshExpiredToken() {
	try {
		const expiredToken = localStorage.getItem(refreshTokenKey);
		if (!expiredToken) return;

		const response = await axios.post("token/refresh/", { refresh: expiredToken });

		if (response.status !== 200 && response.status !== 201) {
			return localStorage.getItem(accessTokenKey);
		} else {
			localStorage.setItem(accessTokenKey, response.data.access);
			return response.data.access;
		}
	} catch (ex) {
		console.log("Error: ", ex);
		alert("Your session has been expired.");
		localStorage.removeItem(refreshTokenKey);
		localStorage.removeItem(accessTokenKey);
		window.location = "/oauth";
	}
}

function logoutUser() {
	localStorage.removeItem(refreshTokenKey);
	localStorage.removeItem(accessTokenKey);
	window.location = "/oauth";
}

export default getToken;
