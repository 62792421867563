// Get Requests Data
export const FETCH_REQUESTS_DATA_REQUEST = "FETCH_REQUESTS_DATA_REQUEST";
export const FETCH_REQUESTS_DATA_SUCCESS = "FETCH_REQUESTS_DATA_SUCCESS";
export const FETCH_REQUESTS_DATA_FAILURE = "FETCH_REQUESTS_DATA_FAILURE";

// Get Completed Loans
export const FETCH_COMPLETED_LOANS_REQUEST = "FETCH_COMPLETED_LOANS_REQUEST";
export const FETCH_COMPLETED_LOANS_SUCCESS = "FETCH_COMPLETED_LOANS_SUCCESS";
export const FETCH_COMPLETED_LOANS_FAILURE = "FETCH_COMPLETED_LOANS_FAILURE";

// Get Current Loans
export const FETCH_CURRENT_LOANS_REQUEST = "FETCH_CURRENT_LOANS_REQUEST";
export const FETCH_CURRENT_LOANS_SUCCESS = "FETCH_CURRENT_LOANS_SUCCESS";
export const FETCH_CURRENT_LOANS_FAILURE = "FETCH_CURRENT_LOANS_FAILURE";

// Get Processing Loans
export const FETCH_PROCESSING_LOANS_REQUEST = "FETCH_PROCESSING_LOANS_REQUEST";
export const FETCH_PROCESSING_LOANS_SUCCESS = "FETCH_PROCESSING_LOANS_SUCCESS";
export const FETCH_PROCESSING_LOANS_FAILURE = "FETCH_PROCESSING_LOANS_FAILURE";

// Get Collections Loans
export const FETCH_COLLECTIONS_LOANS_REQUEST = "FETCH_COLLECTIONS_LOANS_REQUEST";
export const FETCH_COLLECTIONS_LOANS_SUCCESS = "FETCH_COLLECTIONS_LOANS_SUCCESS";
export const FETCH_COLLECTIONS_LOANS_FAILURE = "FETCH_COLLECTIONS_LOANS_FAILURE";

// Get Default Loans
export const FETCH_DEFAULT_LOANS_REQUEST = "FETCH_DEFAULT_LOANS_REQUEST";
export const FETCH_DEFAULT_LOANS_SUCCESS = "FETCH_DEFAULT_LOANS_SUCCESS";
export const FETCH_DEFAULT_LOANS_FAILURE = "FETCH_DEFAULT_LOANS_FAILURE";

// Get More Completed Loans
export const FETCH_MORE_COMPLETED_LOANS_REQUEST = "FETCH_MORE_COMPLETED_LOANS_REQUEST";
export const FETCH_MORE_COMPLETED_LOANS_SUCCESS = "FETCH_MORE_COMPLETED_LOANS_SUCCESS";
export const FETCH_MORE_COMPLETED_LOANS_FAILURE = "FETCH_MORE_COMPLETED_LOANS_FAILURE";

// Get More Current Loans
export const FETCH_MORE_CURRENT_LOANS_REQUEST = "FETCH_MORE_CURRENT_LOANS_REQUEST";
export const FETCH_MORE_CURRENT_LOANS_SUCCESS = "FETCH_MORE_CURRENT_LOANS_SUCCESS";
export const FETCH_MORE_CURRENT_LOANS_FAILURE = "FETCH_MORE_CURRENT_LOANS_FAILURE";

// Get More Processing Loans
export const FETCH_MORE_PROCESSING_LOANS_REQUEST = "FETCH_MORE_PROCESSING_LOANS_REQUEST";
export const FETCH_MORE_PROCESSING_LOANS_SUCCESS = "FETCH_MORE_PROCESSING_LOANS_SUCCESS";
export const FETCH_MORE_PROCESSING_LOANS_FAILURE = "FETCH_MORE_PROCESSING_LOANS_FAILURE";

// Get More Collections Loans
export const FETCH_MORE_COLLECTIONS_LOANS_REQUEST = "FETCH_MORE_COLLECTIONS_LOANS_REQUEST";
export const FETCH_MORE_COLLECTIONS_LOANS_SUCCESS = "FETCH_MORE_COLLECTIONS_LOANS_SUCCESS";
export const FETCH_MORE_COLLECTIONS_LOANS_FAILURE = "FETCH_MORE_COLLECTIONS_LOANS_FAILURE";

// Get More Default Loans
export const FETCH_MORE_DEFAULT_LOANS_REQUEST = "FETCH_MORE_DEFAULT_LOANS_REQUEST";
export const FETCH_MORE_DEFAULT_LOANS_SUCCESS = "FETCH_MORE_DEFAULT_LOANS_SUCCESS";
export const FETCH_MORE_DEFAULT_LOANS_FAILURE = "FETCH_MORE_DEFAULT_LOANS_FAILURE";

// Fetch Loan Payments Data By ID
export const FETCH_LOAN_PAYMENTS_BY_ID_REQUEST = "FETCH_LOAN_PAYMENTS_BY_ID_REQUEST";
export const FETCH_LOAN_PAYMENTS_BY_ID_SUCCESS = "FETCH_LOAN_PAYMENTS_BY_ID_SUCCESS";
export const FETCH_LOAN_PAYMENTS_BY_ID_FAILURE = "FETCH_LOAN_PAYMENTS_BY_ID_FAILURE";

// Check Balance By ID
export const FETCH_CHECK_BALANCE_BY_ID_REQUEST = "FETCH_CHECK_BALANCE_BY_ID_REQUEST";
export const FETCH_CHECK_BALANCE_BY_ID_SUCCESS = "FETCH_CHECK_BALANCE_BY_ID_SUCCESS";
export const FETCH_CHECK_BALANCE_BY_ID_FAILURE = "FETCH_CHECK_BALANCE_BY_ID_FAILURE";

// Enterprise Fund By ID
export const FETCH_ENTERPRISE_FUND_BY_ID_REQUEST = "FETCH_ENTERPRISE_FUND_BY_ID_REQUEST";
export const FETCH_ENTERPRISE_FUND_BY_ID_SUCCESS = "FETCH_ENTERPRISE_FUND_BY_ID_SUCCESS";
export const FETCH_ENTERPRISE_FUND_BY_ID_FAILURE = "FETCH_ENTERPRISE_FUND_BY_ID_FAILURE";

// Dismiss Loan
export const DISMISS_LOAN_REQUEST = "DISMISS_LOAN_REQUEST";
export const DISMISS_LOAN_SUCCESS = "DISMISS_LOAN_SUCCESS";
export const DISMISS_LOAN_FAILURE = "DISMISS_LOAN_FAILURE";

// Cancel Loan
export const CANCEL_LOAN_REQUEST = "CANCEL_LOAN_REQUEST";
export const CANCEL_LOAN_SUCCESS = "CANCEL_LOAN_SUCCESS";
export const CANCEL_LOAN_FAILURE = "CANCEL_LOAN_FAILURE";
