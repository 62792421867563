import React, { Component } from "react";
import PropTypes from "prop-types";

// Components
import InfoIcon from "../common/infoIcon/infoIcon";

// Import CSS
import "./infoCard.css";

class InfoCard extends Component {
	// Refs
	InfoCard = React.createRef();

	// Helper Methods
	handleCardColor = (label, value) => {
		switch (label) {
			case "Payment History":
				if (value <= 97) return "#FF453D";
				if (value < 98) return "#FFA42C";
				if (value <= 99) return "#00A895";
				if (value > 99) return "#016BED";
				return "#000";

			case "Credit Utilization":
				if (value <= 9) return "#0069ED";
				if (value <= 29) return "#00A694";
				if (value <= 49) return "#FFA226";
				if (value <= 74) return "#FF9993";
				if (value > 74) return "#FF4038";
				return "#000";

			case "Derogatory Marks":
				if (value >= 4) return "#FF453D";
				if (value >= 2 && value <= 3) return "#FFA42C";
				if (value === 1) return "#00A895";
				if (value === 0) return "#016BED";
				return "#000";

			case "Hard Inquiries":
				if (value >= 9) return "#FF4038";
				if (value >= 5 && value <= 8) return "#FF9993";
				if (value >= 3 && value <= 4) return "#FFA226";
				if (value >= 1 && value <= 2) return "#00A694";
				if (value === 0) return "#0069ED";
				return "#000";

			case "Annual Income":
				return "#00AC9B";

			case "Total Accounts":
				if (value <= 5) return "#FF453D";
				if (value >= 6 && value <= 10) return "#FFA42C";
				if (value >= 11 && value <= 20) return "#00A895";
				if (value >= 21) return "#016BED";
				return "#000";

			case "Lenme History":
				return "#BF2BFF";

			default:
				console.log("Info Card label invalid.");
				return "#000";
		}
	};

	render() {
		const { label, value, string, show_info_function } = this.props;

		// Get infocard color respective to value
		const color = this.handleCardColor(label, value);

		// Render
		//If lenme history clickable everywhere
		if (label === "Lenme History") {
			if (parseInt(string.charAt(0)) === 0) {
				//If Lenme history && no history, not clickable
				return (
					<div ref={this.InfoCard} className="info-card card p-3" style={{ borderColor: color }}>
						<div className="info-card-label">{label}</div>
						<div className="info-card-value">{string}</div>
						<InfoIcon classes="info-card-info-icon" />
					</div>
				);
			}

			return (
				<div
					ref={this.InfoCard}
					className="info-card card p-3 pointer"
					style={{ borderColor: color }}
					onClick={() => show_info_function()}>
					<div className="info-card-label">{label}</div>
					<div className="info-card-value">{string}</div>
					<InfoIcon classes="info-card-info-icon" />
				</div>
			);
		}

		return (
			<div ref={this.InfoCard} className="info-card card p-3" style={{ borderColor: color }}>
				<div className="info-card-label">{label}</div>
				<div className="info-card-value">{string}</div>
				<InfoIcon handleClick={() => show_info_function()} classes="info-card-info-icon" />
			</div>
		);
	}
}

// Prop Types
InfoCard.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.any,
	string: PropTypes.string.isRequired,
	show_info_function: PropTypes.func.isRequired,
};

export default InfoCard;
