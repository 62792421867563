import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import history from "../../history";

// Import Images
import NotificationsIcon from "../../img/icons/white/notifications.svg";
import DashboardIcon from "../../img/icons/white/dashboard.svg";
import FeedIcon from "../../img/icons/white/feed.svg";
import InvestmentIcon from "../../img/icons/white/investment.svg";
import BankTransferIcon from "../../img/icons/white/bank-transfer.svg";
import SettingsIcon from "../../img/icons/white/settings.svg";
import SupportIcon from "../../img/icons/white/support.svg";
import LogoutIcon from "../../img/icons/white/logout.svg";
import CloseIcon from "../../img/icons/white/close.svg";

// Import CSS
import "./sideBar.css";

const SideBar = ({ handleClose }) => {
	const [investmentCollapsed, setInvestmentCollapsed] = useState(true);

	// Render
	return (
		<div id="sidebar" className="sidebar d-none d-md-block">
			<div onClick={handleClose} className="overlay"></div>

			<div className="items">
				<div onClick={handleClose} className="close">
					<img src={CloseIcon} alt="x" />
				</div>

				<NavLink to="/user/notification" className="sidebar-item d-sm-none" onClick={handleClose}>
					<img src={NotificationsIcon} alt="Icon" />
					<span>Notifications</span>
				</NavLink>

				<NavLink to="/user/dashboard" className="sidebar-item" onClick={handleClose}>
					<img src={DashboardIcon} alt="Icon" />
					<span>Dashboard</span>
				</NavLink>

				<NavLink to="/user/feed" className="sidebar-item d-md-none" onClick={handleClose}>
					<img src={FeedIcon} alt="Icon" />
					<span>Feed</span>
				</NavLink>

				<NavLink to="/user/investment" className="sidebar-item d-none d-md-block" onClick={handleClose}>
					<img src={InvestmentIcon} alt="Icon" />
					<span>Investment</span>
				</NavLink>

				<section
					onClick={() => setInvestmentCollapsed(!investmentCollapsed)}
					className="sidebar-section d-md-none">
					<div
						to="/user/investment"
						className={`sidebar-item ${
							history.location.pathname.startsWith("/user/investment") ? "active" : ""
						}`}>
						<img src={InvestmentIcon} alt="Icon" />
						<span>Investment</span>
					</div>

					{!investmentCollapsed && (
						<div>
							<NavLink to="/user/investment" className="sidebar-item" onClick={handleClose} exact>
								All
							</NavLink>

							<NavLink to="/user/investment/processing" className="sidebar-item" onClick={handleClose}>
								Processing
							</NavLink>

							<NavLink to="/user/investment/current" className="sidebar-item" onClick={handleClose}>
								Current
							</NavLink>

							<NavLink to="/user/investment/completed" className="sidebar-item" onClick={handleClose}>
								Completed
							</NavLink>

							<NavLink to="/user/investment/collections" className="sidebar-item" onClick={handleClose}>
								Collections
							</NavLink>
						</div>
					)}
				</section>

				<NavLink to="/user/bank-transfer" className="sidebar-item" onClick={handleClose}>
					<img src={BankTransferIcon} alt="Icon" />
					<span>Bank Transfer</span>
				</NavLink>

				<NavLink to="/user/settings" className="sidebar-item" onClick={handleClose}>
					<img src={SettingsIcon} alt="Icon" />
					<span>Settings</span>
				</NavLink>

				<NavLink to="/user/support" className="sidebar-item" onClick={handleClose}>
					<img src={SupportIcon} alt="Icon" />
					<span>Support</span>
				</NavLink>

				<NavLink to="/logout" className="sidebar-item">
					<img src={LogoutIcon} alt="Icon" />
					<span>Logout</span>
				</NavLink>
			</div>
		</div>
	);
};

// Prop Types
SideBar.propTypes = {
	handleClose: PropTypes.func.isRequired,
};

export default SideBar;
