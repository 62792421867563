import React from "react";
import PropTypes from "prop-types";

// Import Images
import Bullet_01 from "../../../img/assets/bullet-01.png";
import Bullet_02 from "../../../img/assets/bullet-02.png";
import Bullet_03 from "../../../img/assets/bullet-03.png";
import Bullet_04 from "../../../img/assets/bullet-04.png";

// Import CSS
import "./LNCheckBoxWithBullets.css";

const LNCheckBoxWithBullets = ({ label, name, value, checked = false, onChange }) => {
	// Helper Methods
	function handleBullets() {
		if (value === "any") return Bullet_01;
		if (value === "2") return Bullet_04;
		if (value === "1") return Bullet_03;
		if (value === "0") return Bullet_02;
	}

	// Render
	return (
		<div className="custom-control custom-checkbox">
			<input
				type="checkbox"
				name={name}
				className="custom-control-input"
				id={name}
				onChange={onChange}
				value={value}
				checked={checked}
			/>
			<label className="custom-control-label" htmlFor={name}>
				<img src={handleBullets()} alt="•" className="checkbox-bullet" /> {label}
			</label>
		</div>
	);
};

// Prop Types
LNCheckBoxWithBullets.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
};

export default LNCheckBoxWithBullets;
