import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";

// Import Services
import http from "../../../services/httpService";

// Import Utils
import { handleReactGAEvents } from "../../../utils/handleReactGAEvents";

// Import Components
import LNFloatingInput from "../../common/LNFloatingInput/LNFloatingInput";
import LNInputMask from "../../common/LNInputMask/LNInputMask";
import LNDatePicker from "../../common/LNDatePicker/LNDatePicker";
import LNRadioInput from "../../common/LNRadioInput/LNRadioInput";
import LNSelect from "../../common/LNSelect/LNSelect";
import LNUpload from "../../common/LNUpload/LNUpload";
import LNError from "../../common/LNError/LNError";

// Import CSS
import "./companyInfo.css";

import store from "../../../redux/store";

class CompanyInfo extends Component {
	state = {
		data: {
			type: "",
			dba: "",
			ssn: "",
			dob: "",
			file_type: "",
			legal_document_number: "",
			legal_document_file: "",
			sole_file: "",
			article_of_incorporation: "",
		},
		error: false,
		errorMessages: [],
	};

	// Render
	render() {
		const { data, error, errorMessages } = this.state;

		const apiVersion = store.getState().general.apiVersion;
		// Validations
		const ValidationSchema = Yup.object().shape({
			type: Yup.string().max(255, "Too Long!").required("Required field"),
			dba: Yup.string().max(255, "Too Long!"),
			ssn: Yup.string().min(9, "SSN Should be 9 digits.").max(9, "SSN Should be 9 digits."),
			dob: Yup.string()
				.test("dob", "You need to be atleast 18 years old.", (value) => {
					if (value === undefined) {
						return true;
					} else {
						return moment().diff(moment(new Date(value)), "years") >= 18;
					}
				})
				.nullable(),
		});

		// Render
		return (
			<div className="signup-process-form company-info">
				{/* header */}
				<div className="form-header mb-5">
					<div className="main-form-label">Start investing with Lenme Enterprise</div>
					<div>Tell us more about your business.</div>
				</div>

				{/* Form */}
				<Formik
					initialValues={data}
					validationSchema={ValidationSchema}
					onSubmit={(values, { setSubmitting, setErrors }) => {
						setSubmitting(true);

						if (values.type === "sole") {
							this.props.solefunction();
						}

						const postData = {
							type: values.type,
							dba: values.dba,
							legal_document_number: values.legal_document_number,
							legal_document_file: values.legal_document_file,
							article_of_incorporation: values.article_of_incorporation,
							ssn: values.ssn,
							dob: values.dob,
							sole_file: values.sole_file,
						};

						Object.keys(postData).forEach((key) => {
							if (!postData[key]) delete postData[key];
						});

						http.post(`enterprise/v${apiVersion}/register/company_info/`, postData)
							.then((res) => {
								this.setState({ error: false });
								setSubmitting(false);
								this.props.incrementfunction();

								// Send GA event
								handleReactGAEvents("Sign_up_step_2");
							})
							.catch((ex) => {
								console.log("Error: ", ex);
								if (ex.response.status === 400 || ex.response.status === 404) {
									this.setState({
										error: true,
										errorMessages: ex.response.data,
									});
								}

								if (ex.response.status === 500) {
									this.setState({
										error: true,
										errorMessages: "Something went wrong!",
									});
								}

								setErrors(errorMessages);
								setSubmitting(false);
							});
					}}>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						setSubmitting,
						resetForm,
					}) => (
						<form onSubmit={handleSubmit} spellCheck={false}>
							<div className="inputs-wrapper">
								{error && <LNError text={errorMessages} />}

								<LNSelect
									size="lg"
									name="type"
									value={values.type}
									defaultOption={{ value: "", name: "Company type", disabled: false }}
									options={[
										{
											name: "Sole Proprietorship",
											value: "sole",
										},
										{ name: "C-Corporation", value: "c-cop" },
										{ name: "S-Corporation", value: "s-cop" },
										{ name: "LLC", value: "llc" },
										{ name: "Partnership", value: "part" },
									]}
									onChange={(e) => {
										resetForm();
										setFieldValue("type", e.target.value);
									}}
									onBlur={handleBlur}
									error={touched.type && errors.type ? `* ${errors.type}` : ""}
								/>

								<LNFloatingInput
									size="lg"
									type="text"
									optional={true}
									name="dba"
									value={values.dba}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Doing business as"
									error={touched.dba && errors.dba ? `* ${errors.dba}` : ""}
								/>

								{/* Sole */}
								{values.type.trim() !== "" && values.type === "sole" && (
									<div>
										<LNInputMask
											mask="999-99-9999"
											maskChar="X"
											value={values.ssn}
											onChange={(e) => {
												const value = e.target.value.replace(/-/g, "").replace(/X/g, "") || "";
												setFieldValue("ssn", value);
											}}
											onBlur={handleBlur}
											size="lg"
											type="text"
											optional={false}
											name="ssn"
											placeholder="Social security number"
											// text="Example: 123-00-1234"
											error={touched.ssn && errors.ssn ? `* ${errors.ssn}` : ""}
										/>

										<LNDatePicker
											size="lg"
											optional={false}
											name="dob"
											value={values.dob}
											placeholder="Date of birth"
											dateFormat="MM/dd/yyyy"
											onChange={(date) => setFieldValue("dob", moment(date).format("YYYY-MM-DD"))}
											error={touched.dob && errors.dob ? `* ${errors.dob}` : ""}
										/>

										<div className="mt-3">
											<span className="text-muted d-block mb-3">Upload Identification</span>

											<div className="mb-2">
												<div className="mb-2">
													<LNRadioInput
														name="file_type"
														label="ID Card"
														value="idCard"
														onChange={handleChange}
													/>
												</div>

												{values.file_type === "idCard" && (
													<LNUpload
														document_type="idCard"
														change_function={(file_id) => {
															setFieldValue("sole_file", file_id);
														}}
														set_loading={(state) => {
															setSubmitting(state);
														}}
													/>
												)}
											</div>

											<div className="mb-2">
												<div className="mb-2">
													<LNRadioInput
														name="file_type"
														label="Driver's License"
														value="license"
														onChange={handleChange}
													/>
												</div>

												{values.file_type === "license" && (
													<LNUpload
														document_type="license"
														change_function={(file_id) => {
															setFieldValue("sole_file", file_id);
														}}
														set_loading={(state) => {
															setSubmitting(state);
														}}
													/>
												)}
											</div>
										</div>
									</div>
								)}

								{/* Not Sole */}
								{values.type.trim() !== "" && values.type !== "sole" && (
									<div className="mt-3">
										<span className="text-muted d-block mb-3">Upload Article of Incorporation</span>

										<LNUpload
											document_type="article_of_incorporation"
											change_function={(file_id) => {
												setFieldValue("article_of_incorporation", file_id);
											}}
											set_loading={(state) => {
												setSubmitting(state);
											}}
											style={{
												marginTop: "2.5%",
											}}
										/>

										<div className="mt-3">
											<span className="text-muted d-block mb-3">Upload Document</span>

											<div className="mb-2">
												<div className="mb-2">
													<LNRadioInput
														name="file_type"
														label="EIN"
														value="ein"
														onChange={(e) => {
															setFieldValue("legal_document_number", "");
															setFieldValue("legal_document_file", "");
															setFieldValue("file_type", e.target.value);
														}}
													/>
												</div>

												{values.file_type === "ein" && (
													<>
														<LNUpload
															document_type="ein"
															change_function={(file_id) => {
																setFieldValue("legal_document_file", file_id);
															}}
															set_loading={(state) => {
																setSubmitting(state);
															}}
														/>

														<LNInputMask
															mask="99 - 9999999"
															maskChar="X"
															value={values.legal_document_number}
															onChange={(e) => {
																const value =
																	e.target.value
																		// .replace(/-/g, "")
																		.replace(/ /g, "")
																		.replace(/X/g, "") || "";
																setFieldValue("legal_document_number", value);
															}}
															onBlur={handleBlur}
															size="lg"
															type="text"
															optional={false}
															name="legal_document_number"
															placeholder="EIN"
															error={
																touched.legal_document_number &&
																errors.legal_document_number
																	? `* ${errors.legal_document_number}`
																	: ""
															}
														/>
													</>
												)}
											</div>

											<div className="mb-2">
												<div className="mb-2">
													<LNRadioInput
														name="file_type"
														label="Tax ID"
														value="tax"
														onChange={(e) => {
															setFieldValue("legal_document_number", "");
															setFieldValue("legal_document_file", "");
															setFieldValue("file_type", e.target.value);
														}}
													/>
												</div>

												{values.file_type === "tax" && (
													<>
														<LNUpload
															document_type="tax"
															change_function={(file_id) => {
																setFieldValue("legal_document_file", file_id);
															}}
															set_loading={(state) => {
																setSubmitting(state);
															}}
														/>

														<LNInputMask
															mask="99 - 9999999"
															maskChar="X"
															value={values.legal_document_number}
															onChange={(e) => {
																const value =
																	e.target.value
																		//.replace(/-/g, "")
																		.replace(/ /g, "")
																		.replace(/X/g, "") || "";
																setFieldValue("legal_document_number", value);
															}}
															onBlur={handleBlur}
															size="lg"
															type="text"
															optional={false}
															name="legal_document_number"
															placeholder="Tax ID"
															error={
																touched.legal_document_number &&
																errors.legal_document_number
																	? `* ${errors.legal_document_number}`
																	: ""
															}
														/>
													</>
												)}
											</div>
										</div>
									</div>
								)}
							</div>

							<div className="d-flex mt-5 buttons-wrapper">
								<div className="mr-3">
									<Link className="btn btn-white btn-lg btn-block" to="/">
										Save and Exit
									</Link>
								</div>

								<div className="flex-grow-1">
									<button
										type="submit"
										className="btn btn-primary btn-lg btn-block"
										onClick={handleSubmit}
										disabled={isSubmitting}>
										Next
									</button>
								</div>
							</div>
						</form>
					)}
				</Formik>
			</div>
		);
	}
}

// Prop Types
CompanyInfo.propTypes = {
	solefunction: PropTypes.func.isRequired,
	incrementfunction: PropTypes.func.isRequired,
};

export default CompanyInfo;
