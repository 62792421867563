import { sentryDSN, sentryRelease } from "../config/config.json";
import * as Sentry from "@sentry/browser";

// Services
import { getCurrentAccount } from "./authService";
import { getUser } from "./userService";

async function init() {
	Sentry.init({
		dsn: sentryDSN,
		release: sentryRelease,
		environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
	});

	// Check if user is logged in
	if (getCurrentAccount()) {
		// Assign user email to Sentry issue
		const { data } = await getUser();
		Sentry.setUser({ email: data.email });
	}
}

function log(error) {
	Sentry.captureException(error);
	console.log(error);
}

export default {
	init,
	log,
};
