import React, { Component } from "react";
import { Link } from "react-router-dom";

// Import Components
import BankProviders from "../../bankProviders/bankProviders";

// Import Images
import linkAccount from "../../../img/static/link-account.svg";

// Import CSS
import "./linkBank.css";

class LinkBank extends Component {
	state = {
		showProviders: false,
	};

	// Helper Methods
	showProviders = () => {
		this.setState({ showProviders: true });
	};

	hideProviders = () => {
		this.setState({ showProviders: false });
	};

	// Render
	render() {
		const { showProviders } = this.state;

		// Render
		return (
			<div className="signup-process-form link-bank">
				{showProviders && (
					<>
						<BankProviders hideProviders={this.hideProviders} renderdFromSignup={true} />

						<div className="d-flex mt-5 buttons-wrapper">
							<div className="mr-3">
								<Link className="btn btn-white btn-lg btn-block" to="/">
									Save and Exit
								</Link>
							</div>
						</div>
					</>
				)}

				{!showProviders && (
					<>
						{/* header */}
						<div className="form-header mb-4">
							<div className="main-form-label mb-3">You are almost done!</div>
							<div className="mb-3">
								In order for funds to be transferred automatically in and out of your account, you will
								need to link a bank account.
							</div>

							<div className="mb-3">
								Your data is protected. We don't store your bank credentials and use 256-bit encryption.
							</div>
						</div>

						<div className="mt-5">
							<img src={linkAccount} alt="Link Account" />
						</div>

						<div className="d-flex mt-5 buttons-wrapper">
							<div className="mr-3">
								<Link className="btn btn-white btn-lg btn-block" to="/">
									Save and Exit
								</Link>
							</div>

							<div className="flex-grow-1">
								<button
									type="submit"
									className="btn btn-primary btn-lg btn-block"
									onClick={this.showProviders}>
									Next
								</button>
							</div>
						</div>
					</>
				)}
			</div>
		);
	}
}

export default LinkBank;
